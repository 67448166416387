 .root {
  width: 100%;
  height: min-content;
  flex-direction: column;
  overflow: hidden;
}
.navigation {
  height: min-content;
  align-self: stretch;
  align-items: center;
  gap: 141px;
  padding: 25px 4px;
  background-image: linear-gradient(90deg, #0a1b23ff 0%, #08383fff 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  overflow-x: auto;
  overflow-y: hidden;
  
}
.frame4 {
  width: min-content;
  height: min-content;
  flex-direction: column;
  padding: 10px;
}
.logo {
  width: 134.3138px;
  height: 30px;
  background-image: url('../../../public/assets/logo.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.carbonMenu {
  flex: 1;
  align-self: right;
  display: flex;
  justify-content: right;
 
}
.icon {
  width: 50px;
  height: 50px;
}
.section {
  height: min-content;
  align-self: stretch;
  padding: 11px 31px 30px;
  background-image: linear-gradient(90deg, #0a1b23ff 0%, #08383fff 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  overflow: hidden;
}
.frame5 {
  height: min-content;
  flex: 1;
  flex-direction: column;
  place-content: center;
  align-items: center;
  gap: 15px;
}
.brazilianFinancialInfrastructu {
  color: #fff;
  font-size: 32px;
  line-height: 40px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  height: min-content;
  align-self: stretch;
  text-align: center;
  align-items: center;
  flex-direction: column;
}
.weHaveDevelopedFinancialSoluti {
  color: #fff;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  width: 293px;
  height: min-content;
  text-align: justify;
  flex-direction: column;
}
.frame6 {
  position: relative;
  width: 296px;
  height: 203px;
  align-items: center;
}
.tela_home {
  position: absolute;
  left: calc(50% - 1.6591px);
  top: calc(50% - 47px);
  width: 184.6818px;
  height: 100px;
  outline: solid 1px #00dc84;
  outline-offset: -1px;
  border-radius: 5px;
  background-image: url('../../../public/assets/tela_home.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: translate(-50%, -50%);
}
.tela_signup {
  position: absolute;
  left: calc(50% - 93.8518px);
  top: calc(50% - -27px);
  width: 78.2964px;
  height: 100px;
  outline: solid 1px #00dc84;
  outline-offset: -1px;
  border-radius: 5px;
  background-image: url('../../../public/assets/tela_signup.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: translate(-50%, -50%);
}
.tela_swap {
  position: absolute;
  left: calc(50% - -38.7771px);
  top: calc(50% - -47px);
  width: 187.5541px;
  height: 100px;
  outline: solid 1px #00dc84;
  outline-offset: -1px;
  border-radius: 5px;
  background-image: url('../../../public/assets/tela_swap.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: translate(-50%, -50%);
}
.frame7 {
  width: min-content;
  height: min-content;
  place-content: center;
  align-items: flex-start;
  gap: 15px;
}
.frame1 {
  width: min-content;
  height: 40px;
  padding: 10px;
  outline: solid 3px #008884;
  outline-offset: -3px;
  border-radius: 5px;
}
.openYourAccount {
  color: #fff;
  font-size: 12px;
  line-height: 15px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  z-index: 0;
  width: 70px;
  align-self: stretch;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.frame3 {
  width: min-content;
  height: 40px;
  padding: 10px;
  outline: solid 3px #008884;
  outline-offset: -3px;
  border-radius: 5px;
}
.contactUs {
  color: #fff;
  font-size: 12px;
  line-height: 15px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  z-index: 0;
  width: 70px;
  align-self: stretch;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.frame2 {
  width: min-content;
  height: 40px;
  padding: 10px;
  outline: solid 3px #008884;
  outline-offset: -3px;
  border-radius: 5px;
}
.checkOurSDKDocumentation {
  color: #fff;
  font-size: 12px;
  line-height: 15px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  z-index: 0;
  width: 70px;
  align-self: stretch;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.section2 {
  height: min-content;
  align-self: stretch;
  padding: 11px 31px 30px;
  background-color: #fff;
  overflow: hidden;
}
.frame8 {
  height: min-content;
  flex: 1;
  flex-direction: column;
  place-content: center;
  align-items: center;
  gap: 15px;
}
.onOffRampPoweredByBRLA {
  color: #0a1b23;
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  height: min-content;
  align-self: stretch;
  text-align: center;
  align-items: center;
  flex-direction: column;
}
.byUsingOurSDKGlobalCompaniesCa {
  color: #0a1b23;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  width: 296px;
  height: min-content;
  text-align: justify;
  flex-direction: column;
}
.frame22 {
  width: min-content;
  height: 40px;
  padding: 10px;
  outline: solid 3px #008884;
  outline-offset: -3px;
  border-radius: 5px;
}
.checkOurSDKDocumentation2 {
  color: #0a1b23;
  font-size: 12px;
  line-height: 15px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  z-index: 0;
  width: 70px;
  align-self: stretch;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.frame9 {
  position: relative;
  width: 295px;
  height: 146px;
  align-items: center;
}
.mint_BRLA {
  position: absolute;
  left: calc(50% - 73.2441px);
  top: 50%;
  width: 83.5118px;
  height: 100px;
  outline: solid 1px #00dc84;
  outline-offset: -1px;
  border-radius: 5px;
  background-image: url('../../../public/assets/mint_BRLA.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: translate(-50%, -50%);
}
.qR_Code {
  position: absolute;
  left: calc(50% - -80.3333px);
  top: 50%;
  width: 70.6667px;
  height: 100px;
  outline: solid 1px #00dc84;
  outline-offset: -1px;
  border-radius: 5px;
  background-image: url('../../../public/assets/qR_Code.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: translate(-50%, -50%);
}
.section3 {
  height: min-content;
  align-self: stretch;
  padding: 11px 31px 30px;
  background-color: #0a1b23;
  overflow: hidden;
}
.frame10 {
  height: min-content;
  flex: 1;
  flex-direction: column;
  place-content: center;
  align-items: center;
  gap: 15px;
}
.createProductsInBRLA {
  color: #fff;
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  height: min-content;
  align-self: stretch;
  text-align: center;
  align-items: center;
  flex-direction: column;
}
.globalCompaniesCanUseOurSDKToI {
  color: #fff;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  width: 296px;
  height: min-content;
  text-align: justify;
  flex-direction: column;
}
.frame23 {
  width: min-content;
  height: 40px;
  padding: 10px;
  outline: solid 3px #008884;
  outline-offset: -3px;
  border-radius: 5px;
}
.checkOurSDKDocumentation3 {
  color: #fff;
  font-size: 12px;
  line-height: 15px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  z-index: 0;
  width: 70px;
  align-self: stretch;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.swap {
  width: 91.1025px;
  height: 100px;
  outline: solid 1px #00dc84;
  outline-offset: -1px;
  border-radius: 5px;
  background-image: url('../../../public/assets/swap.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.section4 {
  height: min-content;
  align-self: stretch;
  padding: 11px 31px 30px;
  background-color: #fff;
  overflow: hidden;
}
.frame11 {
  height: min-content;
  flex: 1;
  flex-direction: column;
  place-content: center;
  align-items: center;
  gap: 15px;
}
.bRLAAccount {
  color: #0a1b23;
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  height: min-content;
  align-self: stretch;
  text-align: center;
  align-items: center;
  flex-direction: column;
}
.byHavingABRLAAccountGlobalComp {
  width: 296px;
  height: min-content;
  flex-direction: column;
}
.textBlock {
  color: #0a1b23;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
}
.list {
  font-size: 12px;
}
.list ::marker {
  color: #0a1b23;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
}
.textBlock2 {
  margin-top: 0;
  color: #0a1b23;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
}
.labelWrapper {
  font-size: 12px;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  flex-shrink: 1;
}
.label {
  color: #0a1b23;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
}
.textBlock3 {
  margin-top: 0;
  color: #0a1b23;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
}
.textBlock4 {
  margin-top: 0;
  color: #0a1b23;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
}
.frame16 {
  width: min-content;
  height: min-content;
  align-items: flex-start;
  gap: 15px;
}
.frame12 {
  width: min-content;
  height: 40px;
  padding: 10px;
  outline: solid 3px #008884;
  outline-offset: -3px;
  border-radius: 5px;
}
.openYourAccount2 {
  color: #0a1b23;
  font-size: 12px;
  line-height: 15px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  z-index: 0;
  width: 70px;
  align-self: stretch;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.frame32 {
  width: min-content;
  height: 40px;
  padding: 10px;
  outline: solid 3px #008884;
  outline-offset: -3px;
  border-radius: 5px;
}
.contactUs2 {
  color: #0a1b23;
  font-size: 12px;
  line-height: 15px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  z-index: 0;
  width: 70px;
  align-self: stretch;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.tela_home2 {
  width: 221.6181px;
  height: 120px;
  outline: solid 1px #00dc84;
  outline-offset: -1px;
  border-radius: 5px;
  background-image: url('../../../public/assets/tela_home2.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.section5 {
  position: relative;
  height: min-content;
  align-self: stretch;
  padding: 11px 31px 30px;
  overflow: hidden;
  background-color: #0a1b23;
}

.section5::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../../public/assets/section5.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.2;
  z-index: 1;
}


.frame122 {
  height: min-content;
  flex: 1;
  flex-direction: column;
  place-content: center;
  align-items: center;
  gap: 15px;
  z-index: 2;
}
.areYouAnIndividual {
  color: #fff;
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  height: min-content;
  align-self: stretch;
  text-align: center;
  align-items: center;
  flex-direction: column;
}
.youCanAlsoCreateYourBRLAAccoun {
  color: #fff;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  width: 296px;
  height: min-content;
  text-align: justify;
  flex-direction: column;
}
.frame13 {
  width: min-content;
  height: 40px;
  padding: 10px;
  outline: solid 3px #008884;
  outline-offset: -3px;
  border-radius: 5px;
}
.openYourAccount3 {
  color: #fff;
  font-size: 12px;
  line-height: 15px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  z-index: 0;
  width: 70px;
  align-self: stretch;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.frame132 {
  position: relative;
  width: 98.2964px;
  height: 120px;
  align-items: flex-start;
  padding: 10px;
}
.tela_signup2 {
  position: absolute;
  left: 10px;
  top: 10px;
  width: 78.2964px;
  height: 100px;
  outline: solid 1px #00dc84;
  outline-offset: -1px;
  border-radius: 5px;
  background-image: url('../../../public/assets/tela_signup2.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.section6 {
  height: min-content;
  align-self: stretch;
  padding: 11px 0 30px;
  background-image: linear-gradient(90deg, #0a1b23ff 0%, #08383fff 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  overflow: hidden;
}
.frame14 {
  height: min-content;
  flex: 1;
  flex-direction: column;
  place-content: center;
  align-items: center;
  gap: 15px;
  padding: 0 31px;
}
.contact {
  color: #fff;
  font-size: 24px;
  line-height: 28px;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.75px;
  width: 296px;
  height: min-content;
  text-align: center;
  align-items: center;
  flex-direction: column;
}
.form {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
.inputMobileWidth {
  height: min-content;
  align-self: stretch;
  padding: 9px 10px;
  border-radius: 10px;
  background-color: #fff;
}
.divPlaceholder {
  width: min-content;
  height: min-content;
  align-self: flex-start;
  padding: 0 139px 0 0;
  overflow: hidden;
}
.name {
  color: #9ca3af;
  font-size: 14px;
  font-family: IBM Plex Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.inputMobileWidth2 {
  height: min-content;
  align-self: stretch;
  padding: 9px 10px;
  border-radius: 10px;
  background-color: #fff;
}
.divPlaceholder2 {
  width: min-content;
  height: min-content;
  align-self: flex-start;
  padding: 0 135px 0 0;
  overflow: hidden;
}
.phone {
  color: #9ca3af;
  font-size: 14px;
  font-family: IBM Plex Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.inputMobileWidth3 {
  height: min-content;
  align-self: stretch;
  padding: 9px 10px;
  border-radius: 10px;
  background-color: #fff;
}
.divPlaceholder3 {
  width: min-content;
  height: min-content;
  align-self: flex-start;
  padding: 0 117px 0 0;
  overflow: hidden;
}
.company {
  color: #9ca3af;
  font-size: 14px;
  font-family: IBM Plex Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.inputMobileWidth4 {
  height: min-content;
  align-self: stretch;
  padding: 9px 10px;
  border-radius: 10px;
  background-color: #fff;
}
.divPlaceholder4 {
  width: min-content;
  height: min-content;
  align-self: flex-start;
  padding: 0 134px 0 0;
  overflow: hidden;
}
.eMail {
  color: #9ca3af;
  font-size: 14px;
  font-family: IBM Plex Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.textarea {
  position: relative;
  height: 150px;
  align-self: stretch;
  border-radius: 10px;
  background-color: #fff;
  overflow: hidden;
}
.button {
  position: relative;
  width: 73.67px;
  height: 34px;
  align-items: flex-start;
  padding: 10px 10.67px 10px 9px;
  border-radius: 10px;
  background-color: #00dc84;
}
.submit {
  color: #fff;
  font-size: 14px;
  line-height: 14px;
  font-weight: bold;
  font-family: IBM Plex Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  text-transform: uppercase;
  position: absolute;
  left: 9px;
  top: 10px;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
}
.divHs_cos_wrapper_module_16619 {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  place-content: center;
  gap: 11px;
}
.followBRLAOnSocial {
  color: #fff;
  font-size: 12.5px;
  line-height: 20px;
  font-weight: bold;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: 1.13px;
  text-transform: uppercase;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.divSocialGrid {
  height: fit-content;
  align-self: stretch;
  align-items: flex-start;
  gap: 24px;
  padding: 0 10px 0 0;
}
.frame {
  width: 10px;
  height: 25px;
  overflow: visible;
}
.icon2 {
 
  height: 100%;
}
.frame15 {
  width: 10px;
  height: 25px;
  overflow: visible;
}
.icon3 {
 
  height: 100%;
}
.eMailInfoBrlaDigital {
  color: #fff;
  font-size: 16px;
  line-height: 18px;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.phone55113777742 {
  color: #fff;
  font-size: 16px;
  line-height: 18px;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.addressRuaUrussui125Cj84SaoPau {
  color: #fff;
  font-size: 16px;
  line-height: 20px;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.digitalAssetsAreSubjectToANumb {
  color: #a7a3b5;
  font-size: 10px;
  line-height: 18px;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  height: min-content;
  align-self: stretch;
  text-align: justify;
  flex-direction: column;
}
._2023BRLADigital {
  color: #a7a3b5;
  font-size: 11.5px;
  line-height: 18px;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.21px;
  height: 12px;
  align-self: stretch;
  justify-content: center;
  flex-direction: column;
}
.frame152 {
  height: min-content;
  align-self: stretch;
  padding: 10px;
}
.logocoin {
  width: 70px;
  height: 70px;
  background-image: url('../../../public/assets/logocoin.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
