.root {
  width: 100%;
  height: min-content;
  flex-direction: column;
}
.navigation {
  height: min-content;
  align-self: stretch;
  align-items: flex-start;
  gap: 141px;
  padding: 15px 4px;
  background-image: linear-gradient(90deg, #0a1b23ff 0%, #08383fff 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  overflow-x: auto;
  overflow-y: hidden;
}
.frame4 {
  width: min-content;
  height: min-content;
  flex-direction: column;
  padding: 10px;
}
.logo {
  width: 134.3138px;
  height: 30px;
  background-image: url('../../../public/assets/logo.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.carbonMenu {
  flex: 1;
  align-self: end;
  justify-content: right;
}
.icon {
  width: 100%;
  height: 100%;
}
.section1 {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  place-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px 44px;
  background-image: linear-gradient(90deg, #0a1b23ff 0%, #08383fff 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
}
.frame39 {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  place-content: center;
  align-items: center;
  gap: 20px;
}
.bRLAToken {
  color: #fff;
  font-size: 32px;
  line-height: 40px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  height: min-content;
  align-self: stretch;
  text-align: center;
  align-items: center;
  flex-direction: column;
}
.bRLAIsABRLPeggedStablecoinCrea {
  color: #fff;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  width: 300px;
  height: min-content;
  text-align: justify;
  flex-direction: column;
}
.frame40 {
  width: 300px;
  height: min-content;
  align-items: center;
  gap: 20px;
}
.frame2 {
  height: 56px;
  flex: 1;
  padding: 10px;
  outline: solid 3px #008884;
  outline-offset: -3px;
  align-items: center;
  border-radius: 5px;
}
.openYourAccount {
  color: #fff;
  font-size: 12px;
  line-height: 18px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  z-index: 0;
  height: fit-content;
  flex: 1;
  text-align: center;
  align-items: center;
  flex-direction: column;
}
.frame1 {
  height: 56px;
  flex: 1;
  padding: 10px;
  outline: solid 3px #008884;
  outline-offset: -3px;
  align-items: center;
  border-radius: 5px;
}
.checkTheWhitepaper {
  color: #fff;
  font-size: 12px;
  line-height: 18px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  z-index: 0;
  height: fit-content;
  flex: 1;
  text-align: center;
  align-items: center;
  flex-direction: column;
}
.frame41 {
  position: relative;
  width: 356px;
  height: 258px;
  align-items: center;
}
.tela_home {
  position: absolute;
  left: calc(50% - -2.8091px);
  top: calc(50% - 58px);
  width: 221.6181px;
  height: 120px;
  outline: solid 1px #00dc84;
  outline-offset: -1px;
  border-radius: 5px;
  background-image: url('../../../public/assets/tela_home.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: translate(-50%, -50%);
}
.tela_swap {
  position: absolute;
  left: calc(50% - -37.5325px);
  top: calc(50% - -43px);
  width: 225.0649px;
  height: 120px;
  outline: solid 1px #00dc84;
  outline-offset: -1px;
  border-radius: 5px;
  background-image: url('../../../public/assets/tela_swap.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: translate(-50%, -50%);
}
.logocoin {
  position: absolute;
  left: calc(50% - 90px);
  top: calc(50% - -57px);
  width: 120px;
  height: 120px;
  background-image: url('../../../public/assets/logocoin.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: translate(-50%, -50%);
}
.section2 {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  place-content: center;
  gap: 20px;
  padding: 20px 30px;
  background-color: #fff;
}
.frame43 {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  place-content: center;
  align-items: center;
  gap: 20px;
}
.transparentAndSecure {
  color: #0a1b23;
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  height: min-content;
  align-self: stretch;
  text-align: center;
  align-items: center;
  flex-direction: column;
}
.allTheBRLATokensAreStrictlyBac {
  color: #0a1b23;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  width: 300px;
  height: min-content;
  text-align: justify;
  flex-direction: column;
}
.frame12 {
  width: 150px;
  height: min-content;
  padding: 10px 0;
  outline: solid 3px #008884;
  outline-offset: -3px;
  border-radius: 5px;
}
.transparencyPage {
  color: #0a1b23;
  font-size: 12px;
  line-height: 18px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  z-index: 0;
  height: min-content;
  flex: 1;
  text-align: center;
  align-items: center;
  flex-direction: column;
}
.frame42 {
  position: relative;
  height: 154px;
  align-self: stretch;
  align-items: flex-start;
  padding: 10px;
}
.image1 {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  background-image: url('../../../public/assets/image1.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: translate(-50%, -50%);
}
.section3 {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  place-content: center;
  gap: 15px;
  padding: 20px 30px;
  background-color: #0a1b23;
}
.frame44 {
  height: min-content;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.accessTheBestOfCrypto {
  color: #fff;
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  height: min-content;
  align-self: stretch;
  text-align: center;
  align-items: center;
  flex-direction: column;
}
.withOver6MillionCryptoUsersInB {
  color: #fff;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  width: 300px;
  height: min-content;
  text-align: justify;
  flex-direction: column;
}
.frame45 {
  position: relative;
  height: 138px;
  align-self: stretch;
  align-items: flex-start;
}
.image2 {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  background-image: url('../../../public/assets/image2.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: translate(-50%, -50%);
}
.section4 {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  place-content: center;
  gap: 20px;
  padding: 20px 30px;
  background-color: #fff;
}
.frame47 {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  place-content: center;
  align-items: center;
  gap: 11px;
}
.bRLAAccount {
  color: #0a1b23;
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  height: min-content;
  align-self: stretch;
  text-align: center;
  align-items: center;
  flex-direction: column;
}
.createYourBRLAAccountAndStartU {
  color: #0a1b23;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  width: 300px;
  height: min-content;
  text-align: justify;
  flex-direction: column;
}
.frame13 {
  width: 150px;
  height: min-content;
  padding: 10px;
  outline: solid 3px #008884;
  outline-offset: -3px;
  border-radius: 5px;
}
.openYourAccount2 {
  color: #0a1b23;
  font-size: 12px;
  line-height: 18px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  z-index: 0;
  height: min-content;
  flex: 1;
  text-align: center;
  align-items: center;
  flex-direction: column;
}
.frame46 {
  position: relative;
  height: 176px;
  align-self: stretch;
  align-items: flex-start;
  padding: 10px;
}
.tela_home2 {
  position: absolute;
  left: 50%;
  top: calc(50% - -0.2208px);
  width: 300px;
  height: 162.4416px;
  outline: solid 1px #00dc84;
  outline-offset: -1px;
  border-radius: 5px;
  background-image: url('../../../public/assets/tela_home2.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: translate(-50%, -50%);
}
.section6 {
  height: min-content;
  align-self: stretch;
  padding: 11px 0 30px;
  background-image: linear-gradient(90deg, #0a1b23ff 0%, #08383fff 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  overflow: hidden;
}
.frame14 {
  height: min-content;
  flex: 1;
  flex-direction: column;
  place-content: center;
  align-items: center;
  gap: 15px;
  padding: 0 31px;
}
.contact {
  color: #fff;
  font-size: 24px;
  line-height: 28px;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.75px;
  width: 296px;
  height: min-content;
  text-align: center;
  align-items: center;
  flex-direction: column;
}
.form {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
.inputMobileWidth {
  height: min-content;
  align-self: stretch;
  padding: 9px 10px;
  border-radius: 10px;
  background-color: #fff;
}
.divPlaceholder {
  width: min-content;
  height: min-content;
  align-self: flex-start;
  padding: 0 139px 0 0;
  overflow: hidden;
}
.name {
  color: #9ca3af;
  font-size: 14px;
  font-family: IBM Plex Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.inputMobileWidth2 {
  height: min-content;
  align-self: stretch;
  padding: 9px 10px;
  border-radius: 10px;
  background-color: #fff;
}
.divPlaceholder2 {
  width: min-content;
  height: min-content;
  align-self: flex-start;
  padding: 0 135px 0 0;
  overflow: hidden;
}
.phone {
  color: #9ca3af;
  font-size: 14px;
  font-family: IBM Plex Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.inputMobileWidth3 {
  height: min-content;
  align-self: stretch;
  padding: 9px 10px;
  border-radius: 10px;
  background-color: #fff;
}
.divPlaceholder3 {
  width: min-content;
  height: min-content;
  align-self: flex-start;
  padding: 0 117px 0 0;
  overflow: hidden;
}
.company {
  color: #9ca3af;
  font-size: 14px;
  font-family: IBM Plex Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.inputMobileWidth4 {
  height: min-content;
  align-self: stretch;
  padding: 9px 10px;
  border-radius: 10px;
  background-color: #fff;
}
.divPlaceholder4 {
  width: min-content;
  height: min-content;
  align-self: flex-start;
  padding: 0 134px 0 0;
  overflow: hidden;
}
.eMail {
  color: #9ca3af;
  font-size: 14px;
  font-family: IBM Plex Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.textarea {
  position: relative;
  height: 150px;
  align-self: stretch;
  border-radius: 10px;
  background-color: #fff;
  overflow: hidden;
}
.button {
  position: relative;
  width: 73.67px;
  height: 34px;
  align-items: flex-start;
  padding: 10px 10.67px 10px 9px;
  border-radius: 10px;
  background-color: #00dc84;
}
.submit {
  color: #fff;
  font-size: 14px;
  line-height: 14px;
  font-weight: bold;
  font-family: IBM Plex Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  text-transform: uppercase;
  position: absolute;
  left: 9px;
  top: 10px;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
}
.divHs_cos_wrapper_module_16619 {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  place-content: center;
  gap: 11px;
}
.followBRLAOnSocial {
  color: #fff;
  font-size: 12.5px;
  line-height: 20px;
  font-weight: bold;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: 1.13px;
  text-transform: uppercase;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.divSocialGrid {
  height: min-content;
  align-self: stretch;
  align-items: flex-start;
  gap: 24px;
  padding: 0 96px 0 0;
}
.frame {
  width: 25px;
  height: 25px;
  overflow: visible;
}
.icon2 {
  width: 100%;
  height: 100%;
}
.frame3 {
  width: 21.88px;
  height: 25px;
  overflow: visible;
}
.icon3 {
  width: 100%;
  height: 100%;
}
.eMailInfoBrlaDigital {
  color: #fff;
  font-size: 16px;
  line-height: 18px;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.phone55113777742 {
  color: #fff;
  font-size: 16px;
  line-height: 18px;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.addressRuaUrussui125Cj84SaoPau {
  color: #fff;
  font-size: 16px;
  line-height: 20px;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.digitalAssetsAreSubjectToANumb {
  color: #a7a3b5;
  font-size: 10px;
  line-height: 18px;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  height: min-content;
  align-self: stretch;
  text-align: justify;
  flex-direction: column;
}
._2023BRLADigital {
  color: #a7a3b5;
  font-size: 11.5px;
  line-height: 18px;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.21px;
  height: 12px;
  align-self: stretch;
  justify-content: center;
  flex-direction: column;
}
.frame15 {
  height: min-content;
  align-self: stretch;
  padding: 10px;
}
.logocoin2 {
  width: 70px;
  height: 70px;
  background-image: url('../../../public/assets/logocoin2.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
