._StatusBarTime._StatusBarTime {
    left: 27px;
    top: 14px;
  }
  .Time {
    color: #fffffe;
    font-size: 16px;
    line-height: 21px;
    font-weight: 600;
    font-family: SF Pro Text, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.32px;
    position: absolute;
    left: 0;
    top: 1px;
    width: 54px;
    height: 20px;
    text-align: center;
    align-items: center;
    flex-direction: column;
  }
  ._StatusBarBattery._StatusBarBattery {
    right: 26.5988px;
    left: initial;
    top: 19px;
    bottom: initial;
    width: 27.4012px;
    height: 13px;
  }
  .icon {
    width: 100%;
    height: 100%;
  }
  .icon2 {
    width: 100%;
    height: 100%;
  }
  .icon3 {
    width: 100%;
    height: 100%;
  }
  .root {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 1130px; 
    align-items: flex-start;
    border-radius: 16px;
    background-color: #fff;
    overflow-y: auto;
    overflow-x: hidden;
    
  }

  .root1 {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 1150px; 
    align-items: flex-start;
    border-radius: 16px;
    background-color: #fff;
    overflow-y: auto;
    overflow-x: hidden;
    
  }

  .root2 {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 1250px; 
    align-items: flex-start;
    border-radius: 16px;
    background-color: #fff;
    overflow-y: auto;
    overflow-x: hidden;
    
  }

  .root3 {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 1080px; 
    align-items: flex-start;
    border-radius: 16px;
    background-color: #fff;
    overflow-y: auto;
    overflow-x: hidden;
    
  }

  .root4 {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 1070px; 
    align-items: flex-start;
    border-radius: 16px;
    background-color: #fff;
    overflow-y: auto;
    overflow-x: hidden;
    
  }

  .root5 {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 1270px; 
    align-items: flex-start;
    border-radius: 16px;
    background-color: #fff;
    overflow-y: auto;
    overflow-x: hidden;
    
  }

  
  .root6 {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 1320px; 
    align-items: flex-start;
    border-radius: 16px;
    background-color: #fff;
    overflow-y: auto;
    overflow-x: hidden;
    
  }
  
  
  .rectangle1 {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 300px;
    background-image: linear-gradient(90deg, #0a1b23ff 0%, #08383fff 100%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
  }
  .rectangle2 {
    position: absolute;
    left: 0;
    right: 0;
    top: 266px;
    bottom: 0;
    border-radius: 24px 24px 16px 16px;
    background-color: #fff;
  }
  .body_frame {
    position: absolute;
    left: calc(50% - -0.5px);
    top: 321px;
    width: min-content;
    height: min-content;
    flex-direction: column;
    place-content: center;
    align-items: center;
    gap: 25px;
    transform: translateX(-50%);
  }
  .link {
    width: 325px;
    height: min-content;
    flex-direction: column;
    gap: 15px;
    padding: 10px;
    border-radius: 10px;
    background-color: #f5f5f8;
  }
  .divH2d60786a2a {
    height: min-content;
    align-self: stretch;
    padding: 0 128px 0 0;
  }
  .divTwAvatar {
    width: 48px;
    height: 48px;
    align-items: flex-start;
    border-radius: 24px;
  }
  .divTwAvatar__content {
    height: 48px;
    flex: 1;
    border-radius: 24px;
    overflow: hidden;
  }
  .divCurrencyFlag {
    flex: 1;
    align-self: stretch;
    border-radius: 100px;
    background-image:/* webpackIgnore: true */ url('/assets/divCurrencyFlag.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0px 0px 1px 0px #0009;
  }
  .pseudo {
    width: 48px;
    height: 48px;
    outline: solid 1px #0000001a;
    outline-offset: -1px;
    border-radius: 24px;
  }
  .divBalanceCard_balanceCardText {
    height: min-content;
    align-self: stretch;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }
  .heading25698 {
    color: #08383f;
    font-size: 18px;
    line-height: 18px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .divNpTextBodyDefault {
    width: 176px;
    height: min-content;
    overflow: hidden;
  }
  .realBrasileiro {
    color: #008884;
    font-size: 10px;
    line-height: 10px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: 0.1575px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .link2 {
    width: 325px;
    height: min-content;
    flex-direction: column;
    gap: 15px;
    padding: 10px;
    border-radius: 10px;
    background-color: #f5f5f8;
  }
  .divH2d60786a2a2 {
    height: min-content;
    align-self: stretch;
    padding: 0 128px 0 0;
  }
  .divTwAvatar2 {
    width: 48px;
    height: 48px;
    align-items: flex-start;
    border-radius: 24px;
  }
  .divTwAvatar__content2 {
    height: 48px;
    flex: 1;
    border-radius: 24px;
    overflow: hidden;
  }
  .divCurrencyFlag2 {
    flex: 1;
    align-self: stretch;
    border-radius: 100px;
    background-image:/* webpackIgnore: true */ url('./brazil.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0px 0px 1px 0px #0009;
  }
  .pseudo2 {
    width: 48px;
    height: 48px;
    outline: solid 1px #0000001a;
    outline-offset: -1px;
    border-radius: 24px;
  }
  .divBalanceCard_balanceCardText2 {
    height: min-content;
    align-self: stretch;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }
  .heading256982 {
    color: #08383f;
    font-size: 18px;
    line-height: 18px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .divNpTextBodyDefault2 {
    width: 176px;
    height: min-content;
    overflow: hidden;
  }
  .dolarAmericano {
    color: #008884;
    font-size: 10px;
    line-height: 10px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: 0.1575px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .link3 {
    width: 325px;
    height: min-content;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    padding: 10px;
    border-radius: 10px;
    background-color: #f5f5f8;
  }
  .divBalanceCard_balanceCardText3 {
    width: 300px;
    height: min-content;
    flex-direction: column;
  }
  .heading256983 {
    color: #08383f;
    font-size: 18px;
    line-height: 18px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    height: min-content;
    align-self: stretch;
    flex-direction: column;
  }
  .headerRow {
    height: min-content;
    align-self: stretch;
    align-items: center;
    gap: 30px;
    background-color: #f5f5f8;
  }
  .cell {
    width: min-content;
    height: 183px;
    flex-direction: column;
    place-content: space-between;
    align-items: flex-start;
  }
  .tipo {
    color: #08383f;
    font-size: 14.75px;
    line-height: 22.5px;
    font-weight: bold;
    font-family: Helvetica, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .uilMoneyWithdrawal {
    width: 30px;
    height: 30px;
  }
  .icon4 {
    width: 100%;
    height: 100%;
  }
  .mdiRecurringPayment {
    width: 30px;
    height: 30px;
  }
  .icon5 {
    width: 100%;
    height: 100%;
  }
  .solarCardSendBroken {
    width: 30px;
    height: 30px;
  }
  .icon6 {
    width: 100%;
    height: 100%;
  }
  .solarCardSendBroken2 {
    width: 30px;
    height: 30px;
  }
  .icon7 {
    width: 100%;
    height: 100%;
  }
  .cell2 {
    width: min-content;
    align-self: stretch;
    flex-direction: column;
    place-content: space-between;
    align-items: flex-start;
  }
  .data {
    color: #08383f;
    font-size: 14.75px;
    line-height: 22.5px;
    font-weight: bold;
    font-family: Helvetica, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .aug282023 {
    color: #08383f;
    font-size: 12px;
    line-height: 12px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: 79px;
    height: 30px;
    justify-content: center;
    flex-direction: column;
  }
  .aug272023 {
    color: #08383f;
    font-size: 12px;
    line-height: 12px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: 77px;
    height: 30px;
    justify-content: center;
    flex-direction: column;
  }
  .aug2720232 {
    color: #08383f;
    font-size: 12px;
    line-height: 12px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: 77px;
    height: 30px;
    justify-content: center;
    flex-direction: column;
  }
  .aug2720233 {
    color: #08383f;
    font-size: 12px;
    line-height: 12px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: 77px;
    height: 30px;
    justify-content: center;
    flex-direction: column;
  }
  .cell3 {
    flex: 1;
    align-self: stretch;
    flex-direction: column;
    place-content: space-between;
    align-items: flex-start;
  }
  .valor {
    color: #08383f;
    font-size: 14.75px;
    line-height: 22.5px;
    font-weight: bold;
    font-family: Helvetica, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .R10000 {
    color: #f27a7a;
    font-size: 12px;
    line-height: 12px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    height: 30px;
    align-self: stretch;
    justify-content: center;
    flex-direction: column;
  }
  .frame7 {
    position: relative;
    width: 118px;
    height: 30px;
    gap: 5px;
  }
  .U3000 {
    color: #00dc84;
    font-size: 12px;
    line-height: 12px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    position: absolute;
    left: 0;
    top: 0;
    width: 118px;
    height: 15px;
    justify-content: center;
    flex-direction: column;
  }
  .R15000 {
    color: #f27a7a;
    font-size: 12px;
    line-height: 12px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    position: absolute;
    left: 0;
    top: 17px;
    width: 118px;
    height: 15px;
    justify-content: center;
    flex-direction: column;
  }
  .r5000 {
    color: #00dc84;
    font-size: 12px;
    line-height: 12px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    height: 30px;
    align-self: stretch;
    justify-content: center;
    flex-direction: column;
  }
  .r50000 {
    color: #00dc84;
    font-size: 12px;
    line-height: 12px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    height: 30px;
    align-self: stretch;
    justify-content: center;
    flex-direction: column;
  }
  .menu_frame {
    position: absolute;
    left: calc(50% - -0.5px);
    top: 237px;
    width: min-content;
    height: min-content;
    place-content: center;
    align-items: center;
    gap: 25px;
    padding: 5px 20px;
    border-radius: 16px;
    background-color: #fff;
    box-shadow: 0px 4px 40px 0px #0a011814;
    transform: translateX(-50%);
  }
  .frame1 {
    width: min-content;
    height: min-content;
    flex-direction: column;
    place-content: center;
    align-items: center;
    gap: 8px;
  }
  .solarCardSendBroken3 {
    width: 30px;
    height: 30px;
  }
  .icon8 {
    width: 100%;
    height: 100%;
  }
  .transferir {
    color: #08383f;
    font-size: 10px;
    line-height: 10px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: 1.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .frame2 {
    width: min-content;
    height: min-content;
    flex-direction: column;
    place-content: center;
    align-items: center;
    gap: 8px;
  }
  .fontistoMoneySymbol {
    width: 30px;
    height: 30px;
  }
  .icon9 {
    width: 100%;
    height: 100%;
  }
  .converter {
    color: #08383f;
    font-size: 10px;
    line-height: 10px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: 1.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .frame3 {
    width: min-content;
    height: min-content;
    flex-direction: column;
    place-content: center;
    align-items: center;
    gap: 8px;
  }
  .streamlineMoneyWalletMoneyPaym {
    width: 30px;
    height: 30px;
  }
  .icon10 {
    width: 100%;
    height: 100%;
  }
  .depositar {
    color: #08383f;
    font-size: 10px;
    line-height: 10px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: 1.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .balance_frame {
    position: absolute;
    left: 98px;
    top: 125px;
    width: min-content;
    height: min-content;
    flex-direction: column;
    align-items: center;
    gap: 27px;
  }
  .saldoTotal {
    color: #c8c8c8;
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: 1.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .r40000 {
    color: #fff;
    font-size: 24px;
    line-height: 24px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: 1.4px;
    width: 220px;
    height: min-content;
    flex-direction: column;
  }
  .welcome_frame {
    position: absolute;
    left: 27px;
    top: 55px;
    width: min-content;
    height: min-content;
    align-items: center;
    gap: 11px;
  }
  .spanCbAvatar {
    position: relative;
    width: 35px;
    height: 35px;
    align-items: flex-start;
    border-radius: 9999px;
    background-color: #fff;
  }
  .bD {
    color: #0a1b23;
    font-size: 10px;
    line-height: 10px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    position: absolute;
    left: calc(50% - -0.5px);
    top: calc(50% - -0.5px);
    width: 30px;
    height: min-content;
    text-align: center;
    align-items: center;
    flex-direction: column;
    transform: translate(-50%, -50%);
  }
  .bemVindoBRLADigital {
    color: #fff;
    font-size: 14px;
    line-height: 10px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: 1.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .bottom_Navigation {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 64px;
    align-items: flex-start;
    background-color: #fff;
  }
  .subtract {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50px;
    overflow: visible;
  }
  .icon11 {
    width: 100%;
    height: 100%;
  }
  .spanCbAvatar2 {
    display:flex;
    justify-content: center;
    margin: 0 auto;
    top: calc(50% - 7px);
    width: 50px;
    height: 50px;
    margin-top: 27px;
    align-items: flex-start;
    border-radius: 9999px;
    background-color: #7ef1b3;
    transform: translateY(-50%);
  }
  .unnamed {
    color: #0a1b23;
    font-size: 50px;
    line-height: 32px;
    font-weight: 300;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    position: absolute;
    left: 50%;
    top: calc(50% - -5px);
    width: min-content;
    height: min-content;
    text-align: center;
    align-items: center;
    white-space: nowrap;
    flex-direction: column;
    transform: translate(-50%, -50%);
  }
  .sVG2 {
    position: absolute;
    left: calc(50% - 137.5px);
    bottom: 7px;
    width: 35px;
    height: 35px;
    transform: translateX(-50%);
  }
  .icon12 {
    width: 100%;
    height: 100%;
  }
  .sVG {
    position: absolute;
    left: calc(50% - -77.5px);
    bottom: 8px;
    width: 35px;
    height: 35px;
    transform: translateX(-50%);
  }
  .icon13 {
    width: 100%;
    height: 100%;
  }
  .clarityTwoWayArrowsLine {
    position: absolute;
    left: 100px;
    bottom: 7px;
    width: 35px;
    height: 35px;
  }
  .icon14 {
    width: 100%;
    height: 100%;
  }
  .top_Iphone {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 47px;
    align-items: flex-start;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .notch {
    position: absolute;
    left: 109px;
    top: 0;
    width: 172px;
    height: 32px;
    overflow: visible;
  }
  .icon15 {
    width: 100%;
    height: 100%;
  }
  .wifi {
    position: absolute;
    left: 312px;
    top: 20px;
    width: 17px;
    height: 11.834px;
    overflow: visible;
  }
  .icon16 {
    width: 100%;
    height: 100%;
  }
  .iconMobileSignal {
    position: absolute;
    right: 86px;
    top: 20px;
    width: 18px;
    height: 12px;
    overflow: visible;
  }
  .icon17 {
    width: 100%;
    height: 100%;
  }
  

  .link200 {
    width: 325px;
    height: 193px;
    flex-direction: column;
    padding: 10px;
    border-radius: 10px;
    background-color: #f5f5f8;
  }
  .paraFazerAlteracoesNasSuasConf {
    color: #08383f;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    height: min-content;
    align-self: stretch;
    text-align: justify;
    flex-direction: column;
  }
  

  .x {
    color: #0a1b23;
    font-size: 25px;
    line-height: 25px;
    font-weight: 300;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    position: absolute;
    left: 50%;
    top: calc(50% - -2.5px);
    width: min-content;
    height: min-content;
    text-align: center;
    align-items: center;
    white-space: nowrap;
    flex-direction: column;
    transform: translate(-50%, -50%);
  }
  