.root {
  width: 1728px;
  height: 1117px;
  max-height: 100%;
}
.divZ20 {
  width: min-content;
  align-self: stretch;
  flex-direction: column;
  align-items: center;
  padding: 8px 8px 30px;
  background-image: linear-gradient(90deg, #0a1b23ff 0%, #08383fff 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  box-shadow: 0px 18px 54px 0px #0000002d;
}
.frame9 {
  width: min-content;
  height: min-content;
  flex-direction: column;
  padding: 10px;
}
.headerLinkHomeLogo2a621879dbc6 {
  width: 252px;
  height: 77.03px;
  background-image:/* webpackIgnore: true */ url('/assets/headerLinkHomeLogo2a621879dbc6.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.frame7 {
  width: min-content;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  padding: 50px 0;
}
.link {
  position: relative;
  width: 252px;
  height: 44.5px;
  align-items: flex-start;
  border-radius: 6.75px;
  overflow: hidden;
}
.spanInner {
  position: absolute;
  left: 0;
  right: 1px;
  top: calc(50% - 5.25px);
  height: min-content;
  padding: 5px;
  background-image: linear-gradient(90deg, #0a1b23ff 0%, #08383fff 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  transform: translateY(-50%);
}
.settings {
  color: #fff;
  font-size: 20px;
  line-height: 27px;
  font-weight: bold;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  text-transform: capitalize;
  height: 20px;
  flex: 1;
  justify-content: center;
  flex-direction: column;
}
.link2 {
  position: relative;
  width: 252px;
  height: 44.5px;
  align-items: flex-start;
  border-radius: 6.75px;
  overflow: hidden;
}
.spanInner2 {
  position: absolute;
  left: 0;
  right: 1px;
  top: calc(50% - 0.25px);
  height: min-content;
  align-items: center;
  gap: 20px;
  padding: 5px;
  transform: translateY(-50%);
}
.fluentPersonNote24Filled {
  width: 30px;
  height: 30px;
}
.icon {
  width: 100%;
  height: 100%;
}
.personalInfo {
  color: #fff;
  font-size: 17px;
  line-height: 27px;
  font-weight: bold;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  text-transform: capitalize;
  height: 20px;
  flex: 1;
  justify-content: center;
  flex-direction: column;
}
.link3 {
  position: relative;
  width: 252px;
  height: 44.5px;
  align-items: flex-start;
  border-radius: 6.75px;
  overflow: hidden;
}
.spanInner3 {
  position: absolute;
  left: 0;
  right: 1px;
  top: calc(50% - 0.25px);
  height: min-content;
  align-items: center;
  gap: 20px;
  padding: 5px;
  transform: translateY(-50%);
}
.solarCardSendBroken {
  width: 30px;
  height: 30px;
}
.icon2 {
  width: 100%;
  height: 100%;
}
.recipients {
  color: #fff;
  font-size: 16px;
  line-height: 27px;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  text-transform: capitalize;
  height: 20px;
  flex: 1;
  justify-content: center;
  flex-direction: column;
}
.link4 {
  position: relative;
  width: 252px;
  height: 44.5px;
  align-items: flex-start;
  border-radius: 6.75px;
  overflow: hidden;
}
.spanInner4 {
  position: absolute;
  left: 0;
  right: 1px;
  top: calc(50% - 0.25px);
  height: min-content;
  align-items: center;
  gap: 20px;
  padding: 5px;
  transform: translateY(-50%);
}
.uilMoneyWithdrawal {
  width: 30px;
  height: 30px;
}
.icon3 {
  width: 100%;
  height: 100%;
}
.limits {
  color: #fff;
  font-size: 17px;
  line-height: 27px;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  text-transform: capitalize;
  height: min-content;
  flex: 1;
  flex-direction: column;
}
.frame8 {
  width: min-content;
  height: min-content;
  flex-direction: column;
  place-content: flex-end;
  align-items: center;
  gap: 21px;
}
.link5 {
  position: relative;
  width: 252px;
  height: 44.5px;
  align-items: flex-start;
  border-radius: 6.75px;
  overflow: hidden;
}
.spanInner5 {
  position: absolute;
  left: 0;
  right: 1px;
  top: calc(50% - 0.25px);
  height: min-content;
  align-items: center;
  gap: 20px;
  padding: 5px;
  transform: translateY(-50%);
}
.tablerHome {
  width: 30px;
  height: 30px;
}
.icon4 {
  width: 100%;
  height: 100%;
}
.home {
  color: #fff;
  font-size: 17px;
  line-height: 27px;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  text-transform: capitalize;
  height: 20px;
  flex: 1;
  justify-content: center;
  flex-direction: column;
}
.link6 {
  position: relative;
  width: 215px;
  height: 37.75px;
  align-items: flex-start;
  outline: solid 2px #00dc84;
  outline-offset: -2px;
  border-radius: 6.75px;
  overflow: hidden;
}
.spanInner6 {
  position: absolute;
  left: 2px;
  right: 2px;
  top: 50%;
  height: 33.75px;
  align-items: flex-start;
  border-radius: 6.75px;
  transform: translateY(-50%);
}
.spanCbAvatar {
  position: absolute;
  left: 11.25px;
  top: 50%;
  width: 20.25px;
  height: 20.25px;
  border-radius: 9999px;
  background-color: #fff;
  transform: translateY(-50%);
}
.img {
  position: absolute;
  left: 187.75px;
  top: calc(50% - -0.005px);
  width: 12px;
  height: 12px;
  align-items: flex-start;
  overflow: hidden;
  transform: translateY(-50%);
}
.frame {
  position: absolute;
  left: 50%;
  top: 0;
  width: 12px;
  height: 12px;
  overflow: visible;
  transform: translateX(-50%);
}
.icon5 {
  width: 100%;
  height: 100%;
}
.main {
  flex: 1;
  align-self: stretch;
  flex-direction: column;
  gap: 20px;
  padding: 27px 0;
  background-color: #f9f9fc;
}
.header {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  padding: 0 20px;
}
.divCbHeaderHead {
  height: min-content;
  align-self: stretch;
}
.heading1Home {
  color: #08383f;
  font-size: 32px;
  line-height: 54px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.75px;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.frame25 {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  padding: 0 20px;
}
.section {
  height: 600px;
  align-self: stretch;
  flex-direction: column;
  padding: 40px;
  border-radius: 18px;
  background-color: #fff;
  overflow: hidden;
  box-shadow: 0px 2px 4px -2px #0000001a, 0px 4px 6px -1px #0000001a;
}
.frame6 {
  flex: 1;
  align-self: stretch;
  flex-direction: column;
  gap: 30px;
}
.frame2 {
  height: min-content;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 10px;
}
.name {
  color: #08383f;
  font-size: 18px;
  line-height: 27px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.matheusHenriqueAlvesMoura {
  color: #08383f;
  font-size: 18px;
  line-height: 27px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.frame5 {
  height: min-content;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 10px;
}
.eMail {
  color: #08383f;
  font-size: 18px;
  line-height: 27px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.mmouraBrlaDigital {
  color: #08383f;
  font-size: 18px;
  line-height: 27px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.frame62 {
  height: min-content;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 10px;
}
.password {
  color: #08383f;
  font-size: 18px;
  line-height: 27px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.textBlock {
  display: block;
}
.LastChangeMay251022AM {
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.textBlock2 {
  color: #08383f;
  font-size: 18px;
  line-height: 27px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
}
.textBlock3 {
  margin-top: 0;
}
.labelWrapper {
  font-size: 12px;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  flex-shrink: 1;
}
.label {
  color: #08383f;
  font-size: 12px;
  line-height: 27px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
}
.changePassword {
  color: #00dc84;
  font-size: 18px;
  line-height: 27px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  height: min-content;
  flex: 1;
  text-align: end;
  align-items: end;
  flex-direction: column;
}
.frame3 {
  height: min-content;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 10px;
}
.verification {
  color: #08383f;
  font-size: 18px;
  line-height: 27px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.basicVerification {
  color: #08383f;
  font-size: 18px;
  line-height: 27px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.increaseYourLimits {
  color: #00dc84;
  font-size: 18px;
  line-height: 27px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  height: min-content;
  flex: 1;
  text-align: end;
  align-items: end;
  flex-direction: column;
}
.frame72 {
  height: min-content;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 10px;
}
.mainAsset {
  color: #08383f;
  font-size: 18px;
  line-height: 27px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.rBRL {
  color: #08383f;
  font-size: 18px;
  line-height: 27px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.footer {
  flex: 1;
  align-self: stretch;
  align-items: flex-end;
  padding: 0 20px;
}
._2023BRLADigitalAllRightsReserv {
  color: #08383f;
  font-size: 13.5px;
  line-height: 18px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  height: min-content;
  flex: 1;
  flex-direction: column;
}
.divMlAuto {
  height: min-content;
  flex: 1;
  place-content: flex-end;
  align-items: center;
  gap: 9px;
}
.linkTermsOfUse {
  color: #022c74;
  font-size: 12.5px;
  line-height: 18px;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.linkPrivacyPolicy {
  color: #022c74;
  font-size: 12.5px;
  line-height: 18px;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.linkCookiePolicy {
  color: #022c74;
  font-size: 12.5px;
  line-height: 18px;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
