.root {
    width: 390px;
    height: min-content;
    flex-direction: column;
  }
  .section {
    height: min-content;
    align-self: stretch;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    outline: solid 1px #fff;
    outline-offset: -1px;
    border-radius: 15px;
    background-color: #fff;
  }
  .divH2dC43aaa00 {
    
    height: min-content;
    align-self: stretch;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }
  .frame9 {
    width: min-content;
    height: min-content;
    align-items: center;
    gap: 10px;
  }
  .labelVoceEnviaExatamente {
    color: #454745;
    font-size: 15.75px;
    line-height: 20px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .buttonMax {
    color: #00dc84;
    font-size: 12px;
    line-height: 15.96px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    width: min-content;
    height: min-content;
    text-align: center;
    align-items: center;
    white-space: nowrap;
    flex-direction: column;
  }
  .divTwMoneyInput {
    height: 50px;
    align-self: stretch;
    align-items: center;
    padding: 10px;
    border-radius: 15px;
    background-color: #ffffff01;
    overflow: hidden;
    box-shadow: inset 0px 0px 0px 1px #868685;
  }
  .pseudo {
    width: min-content;
    height: 25.5px;
    align-items: flex-start;
    overflow: hidden;
  }
  ._10000 {
    color: #6a6c6a;
    font-size: 18px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    width: 100px;
    height: min-content;
    flex-direction: column;
  }
  .divDFlex {
    height: min-content;
    flex: 1;
    flex-direction: column;
    align-items: flex-end;
  }
  .iCurrencyFlag {
    width: 24px;
    height: 24px;
    align-items: flex-start;
    border-radius: 12px;
    overflow: hidden;
  }
  .brlSvg {
    width: 24px;
    height: 24px;
  }
  .icon {
    width: 100%;
    height: 100%;
  }
  .divH2dD16ce6e1 {
    
    height: min-content;
    align-self: stretch;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }
  .labelBeneficiarioRecebe {
    color: #454745;
    font-size: 15.75px;
    line-height: 20px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .divTwMoneyInput2 {
    height: 50px;
    align-self: stretch;
    align-items: center;
    padding: 10px;
    border-radius: 15px;
    background-color: #ffffff01;
    overflow: hidden;
    box-shadow: inset 0px 0px 0px 1px #868685;
  }
  .pseudo2 {
    width: min-content;
    height: 25.5px;
    align-items: flex-start;
    overflow: hidden;
  }
  ._99925 {
    color: #6a6c6a;
    font-size: 18px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    width: 71px;
    height: min-content;
    flex-direction: column;
  }
  .divDFlex2 {
    height: min-content;
    flex: 1;
    flex-direction: column;
    align-items: flex-end;
  }
  .iCurrencyFlag2 {
    width: 24px;
    height: 24px;
    align-items: flex-start;
    border-radius: 12px;
    overflow: hidden;
  }
  .brlSvg2 {
    width: 24px;
    height: 24px;
  }
  .icon2 {
    width: 100%;
    height: 100%;
  }
  .divH2dBd04bbe1 {
    
    height: min-content;
    align-self: stretch;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }
  .labelChavePIX {
    color: #454745;
    font-size: 15.75px;
    line-height: 20px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .divTwMoneyInput3 {
    height: 50px;
    align-self: stretch;
    padding: 10px;
    border-radius: 15px;
    background-color: #ffffff01;
    overflow: hidden;
    box-shadow: inset 0px 0px 0px 1px #868685;
  }
  .pseudo3 {
    width: 190px;
    align-self: stretch;
    flex-direction: column;
    place-content: center;
    align-items: flex-start;
    padding: 0 101px 0.5px 0;
    overflow: hidden;
  }
  .digiteSuaChavePIX {
    color: #6a6c6a;
    font-size: 18px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    width: 190px;
    height: min-content;
    flex-direction: column;
  }
  .pseudo4 {
    flex: 1;
    align-self: stretch;
    place-content: flex-end;
    align-items: center;
    overflow: hidden;
  }
  .iCurrencyFlag3 {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    background-image:/* webpackIgnore: true */ url('/assets/iCurrencyFlag.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    box-shadow: inset 0px 0px 0px 1px #0e0f0c1f;
  }
  .alert {
    
    height: 50px;
    align-self: stretch;
    align-items: center;
    padding: 25px;
    border-radius: 16px;
    background-color: #16330014;
  }
  .spanStatusCircle {
    position: relative;
    width: 30px;
    height: 30px;
    align-items: flex-start;
    border-radius: 15px;
    background-color: #454745;
  }
  .sVG {
    position: absolute;
    left: 50%;
    top: -1px;
    width: 32px;
    height: 32px;
    transform: translateX(-50%);
  }
  .icon3 {
    width: 100%;
    height: 100%;
  }
  .divAlert__message {
    height: min-content;
    flex: 1;
    flex-direction: column;
    place-content: center;
    align-items: flex-start;
    gap: 5px;
    padding: 0 0 0 10px;
  }
  .aoContinuarVoceAceitaOsTermosD {
    color: #454745;
    font-size: 12px;
    line-height: 15px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    height: min-content;
    align-self: stretch;
    flex-direction: column;
  }
  .link {
    width: min-content;
    height: min-content;
    gap: 3.15px;
    padding: 0 0.01px 0 0;
    border-radius: 2px;
  }
  .saibaMais {
    color: #163300;
    font-size: 12px;
    line-height: 6px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    width: 74px;
    align-self: stretch;
    justify-content: center;
    flex-direction: column;
  }
  .sVG2 {
    width: 10px;
    align-self: stretch;
  }
  .icon4 {
    width: 100%;
    height: 100%;
  }
  .oValorDeveSerMaiorQueR75EMenor {
    color: #f00;
    font-size: 12px;
    line-height: 15.96px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    
    height: min-content;
    align-self: stretch;
    text-align: center;
    align-items: center;
    flex-direction: column;
  }
  .button {
    opacity: 1;
    
    height: min-content;
    align-self: stretch;
    place-content: center;
    padding: 12px 135.405px 12px 135.595px;
    outline: solid 1px #00dc84;
    outline-offset: -1px;
    border-radius: 9999px;
    background-color: #00dc84;
  }

  .button:disabled {
    opacity: 0.65;
    
    height: min-content;
    align-self: stretch;
    place-content: center;
    padding: 12px 135.405px 12px 135.595px;
    outline: solid 1px #00dc84;
    outline-offset: -1px;
    border-radius: 9999px;
    background-color: #00dc84;
  }
  .confirmarTransferencia {
    color: #163300;
    font-size: 15px;
    line-height: 24px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: 0.08px;
    width: min-content;
    height: min-content;
    text-align: center;
    align-items: center;
    white-space: nowrap;
    flex-direction: column;
  }
  