.root {
    width: 390px;
    height: 236px;
    flex-direction: column;
  }
  .section {
    flex: 1;
    align-self: stretch;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    outline: solid 1px #fff;
    outline-offset: -1px;
    border-radius: 15px;
    background-color: #fff;
  }
  .divH2dC43aaa00 {
    z-index: 0;
    height: min-content;
    align-self: stretch;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }
  .frame9 {
    width: min-content;
    height: min-content;
  }
  .labelVoceEnviaExatamente {
    color: #454745;
    font-size: 15.75px;
    line-height: 20px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .divTwMoneyInput {
    height: 50px;
    align-self: stretch;
    align-items: center;
    padding: 10px;
    border-radius: 15px;
    background-color: #ffffff01;
    overflow: hidden;
    box-shadow: inset 0px 0px 0px 1px #868685;
  }
  .pseudo {
    width: min-content;
    height: 25.5px;
    align-items: flex-start;
    overflow: hidden;
  }
  ._10000 {
    color: #6a6c6a;
    font-size: 18px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    width: 325px;
    height: min-content;
    flex-direction: column;
  }
  .divDFlex {
    height: min-content;
    flex: 1;
    flex-direction: column;
    align-items: flex-end;
  }
  .iCurrencyFlag {
    width: 24px;
    height: 24px;
  }
  .icon {
    width: 100%;
    height: 100%;
  }
  .frame2 {
    z-index: 0;
    height: min-content;
    align-self: stretch;
    flex-direction: column;
    place-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px;
  }
  .pIXQRCode {
    color: #08383f;
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: 118px;
    height: min-content;
    flex-direction: column;
  }
  .image4 {
    width: 200px;
    height: 200px;
    background-image:/* webpackIgnore: true */ url('/assets/image4.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .frame16 {
    width: min-content;
    height: min-content;
    place-content: center;
    align-items: center;
    gap: 10px;
  }
  .pIXCODE123456789 {
    color: #08383f;
    font-size: 11px;
    line-height: 27px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .image5 {
    width: 20px;
    height: 20px;
    background-image:/* webpackIgnore: true */ url('/assets/image5.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .alert {
    z-index: 0;
    height: 50px;
    align-self: stretch;
    align-items: center;
    padding: 25px;
    border-radius: 16px;
    background-color: #16330014;
  }
  .spanStatusCircle {
    position: relative;
    width: 30px;
    height: 30px;
    align-items: flex-start;
    border-radius: 15px;
    background-color: #454745;
  }
  .sVG {
    position: absolute;
    left: 50%;
    top: -1px;
    width: 32px;
    height: 32px;
    transform: translateX(-50%);
  }
  .icon2 {
    width: 100%;
    height: 100%;
  }
  .divAlert__message {
    height: min-content;
    flex: 1;
    flex-direction: column;
    place-content: center;
    align-items: flex-start;
    gap: 5px;
    padding: 0 0 0 10px;
  }
  .aoContinuarVoceAceitaOsTermosD {
    color: #454745;
    font-size: 12px;
    line-height: 15px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    height: min-content;
    align-self: stretch;
    flex-direction: column;
  }
  .link {
    width: min-content;
    height: min-content;
    gap: 3.15px;
    padding: 0 0.01px 0 0;
    border-radius: 2px;
  }
  .saibaMais {
    color: #163300;
    font-size: 12px;
    line-height: 6px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    width: 74px;
    align-self: stretch;
    justify-content: center;
    flex-direction: column;
  }
  .sVG2 {
    width: 10px;
    align-self: stretch;
  }
  .icon3 {
    width: 100%;
    height: 100%;
  }
  .oValorDeveSerMaiorQueR75EMenor {
    color: #f00;
    font-size: 12px;
    line-height: 15.96px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    z-index: 0;
    height: min-content;
    align-self: stretch;
    text-align: center;
    align-items: center;
    flex-direction: column;
  }
  