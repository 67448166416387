.root {
    width: 419px;
    height: min-content;
    flex-direction: column;
    padding: 30px 10px;
    border-radius: 18px;
    background-image: linear-gradient(90deg, #0a1b23ff 0%, #08383fff 100%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    overflow: hidden;
    box-shadow: 0px 2px 4px -2px #0000001a, 0px 4px 6px -1px #0000001a;
  }
  .frame16 {
    height: min-content;
    align-self: stretch;
    flex-direction: column;
  }
  .frame2 {
    height: min-content;
    align-self: stretch;
    flex-direction: column;
    place-content: center;
    align-items: center;
    gap: 10px;
  }
  .transferReceipt {
    color: #fff;
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .frame20 {
    height: min-content;
    align-self: stretch;
    align-items: center;
    gap: 10px;
  }
  .conversionTime {
    color: #fff;
    font-size: 14px;
    line-height: 27px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    height: min-content;
    flex: 1;
    flex-direction: column;
  }
  .jul32023345PM {
    color: #fff;
    font-size: 12px;
    line-height: 27px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .frame24 {
    height: min-content;
    align-self: stretch;
    align-items: center;
    gap: 10px;
  }
  .uSDTReceivedAmout {
    color: #fff;
    font-size: 14px;
    line-height: 27px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    height: min-content;
    flex: 1;
    flex-direction: column;
  }
  ._736 {
    color: #fff;
    font-size: 12px;
    line-height: 27px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .frame23 {
    height: min-content;
    align-self: stretch;
    align-items: center;
    gap: 10px;
  }
  .uSDTBRLSpotPrice {
    color: #fff;
    font-size: 14px;
    line-height: 27px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    height: min-content;
    flex: 1;
    flex-direction: column;
  }
  ._47983 {
    color: #fff;
    font-size: 12px;
    line-height: 27px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .frame21 {
    height: min-content;
    align-self: stretch;
    align-items: center;
    gap: 10px;
  }
  .totalFees {
    color: #fff;
    font-size: 14px;
    line-height: 27px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    height: min-content;
    flex: 1;
    flex-direction: column;
  }
  ._0736USDT {
    color: #fff;
    font-size: 12px;
    line-height: 27px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .frame22 {
    height: min-content;
    align-self: stretch;
    align-items: center;
    gap: 10px;
  }
  .bRLReceivedAmount {
    color: #fff;
    font-size: 14px;
    line-height: 27px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    height: min-content;
    flex: 1;
    flex-direction: column;
  }
  ._3528BRL {
    color: #fff;
    font-size: 12px;
    line-height: 27px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  