._StatusBarTime._StatusBarTime {
    left: 27px;
    top: 14px;
  }
  .Time {
    color: #fffffe;
    font-size: 16px;
    line-height: 21px;
    font-weight: 600;
    font-family: SF Pro Text, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.32px;
    position: absolute;
    left: 0;
    top: 1px;
    width: 54px;
    height: 20px;
    text-align: center;
    align-items: center;
    flex-direction: column;
  }
  ._StatusBarBattery._StatusBarBattery {
    right: 26.5988px;
    left: initial;
    top: 19px;
    bottom: initial;
    width: 27.4012px;
    height: 13px;
  }
  .icon {
    width: 100%;
    height: 100%;
  }
  .icon2 {
    width: 100%;
    height: 100%;
  }
  .icon3 {
    width: 100%;
    height: 100%;
  }
  .root {
    position: relative;
    width: 100%;
    height: 936px;
    align-items: flex-start;
    border-radius: 16px;
    background-color: #fff;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .rectangle1 {
   
    left: 0;
    right: 0;
    top: 0;
    height: 300px;
    background-image: linear-gradient(90deg, #0a1b23ff 0%, #08383fff 100%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
  }
  .rectangle2 {
    left: 0;
    right: 0;
    top: 266px;
    bottom: 0;
    border-radius: 24px 24px 16px 16px;
    background-color: #fff;
  }
  .capturaDeTela2023829As22201 {
    position: absolute;
    left: 0;
    top: 300px;
    width: 390px;
    height: 296px;
    background-image:/* webpackIgnore: true */ url('/assets/capturaDeTela2023829As22201.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .capturaDeTela2023829As22202 {
    position: absolute;
    left: 14px;
    top: 518px;
    width: 357px;
    height: 42px;
    background-image:/* webpackIgnore: true */ url('/assets/capturaDeTela2023829As22202.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .linkHomeLogo2a621879dbc6828fb1 {
    position: absolute;
    left: 50%;
    top: 111px;
    width: 252px;
    height: 77.04px;
    background-image:/* webpackIgnore: true */ url('/assets/linkHomeLogo2a621879dbc6828fb1.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    transform: translateX(-50%);
  }
  .bottom_Navigation {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 390px;
    height: 64px;
    align-items: flex-start;
    background-color: #fff;
  }
  .subtract {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 390px;
    height: 50px;
    overflow: visible;
  }
  .icon4 {
    width: 100%;
    height: 100%;
  }
  .spanCbAvatar {
    position: absolute;
    left: 170px;
    top: calc(50% - 7px);
    width: 50px;
    height: 50px;
    align-items: flex-start;
    border-radius: 9999px;
    background-color: #7ef1b3;
    transform: translateY(-50%);
  }
  .unnamed {
    color: #0a1b23;
    font-size: 50px;
    line-height: 32px;
    font-weight: 300;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    position: absolute;
    left: 50%;
    top: calc(50% - -5px);
    width: min-content;
    height: min-content;
    text-align: center;
    align-items: center;
    white-space: nowrap;
    flex-direction: column;
    transform: translate(-50%, -50%);
  }
  .sVG2 {
    position: absolute;
    left: calc(50% - 137.5px);
    bottom: 7px;
    width: 35px;
    height: 35px;
    transform: translateX(-50%);
  }
  .icon5 {
    width: 100%;
    height: 100%;
  }
  .sVG {
    position: absolute;
    left: calc(50% - -77.5px);
    bottom: 8px;
    width: 35px;
    height: 35px;
    transform: translateX(-50%);
  }
  .icon6 {
    width: 100%;
    height: 100%;
  }
  .clarityTwoWayArrowsLine {
    position: absolute;
    left: 100px;
    bottom: 7px;
    width: 35px;
    height: 35px;
  }
  .icon7 {
    width: 100%;
    height: 100%;
  }
  .top_Iphone {
    position: absolute;
    left: 0;
    top: 0;
    width: 390px;
    height: 47px;
    align-items: flex-start;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .notch {
    position: absolute;
    left: 109px;
    top: 0;
    width: 172px;
    height: 32px;
    overflow: visible;
  }
  .icon8 {
    width: 100%;
    height: 100%;
  }
  .wifi {
    position: absolute;
    left: 312px;
    top: 20px;
    width: 17px;
    height: 11.834px;
    overflow: visible;
  }
  .icon9 {
    width: 100%;
    height: 100%;
  }
  .iconMobileSignal {
    position: absolute;
    right: 86px;
    top: 20px;
    width: 18px;
    height: 12px;
    overflow: visible;
  }
  .icon10 {
    width: 100%;
    height: 100%;
  }
  