      /* ### BEGIN: Hide Account-Chooser iFrame ### */
      #account-chooser-iframe {
        display: none !important;
      }
      /* ### END: Hide Account-Chooser iFrame ### */

      /* ### BEGIN: Circle brand CSS overrides ### */
      

      /* BEGIN: Coolwhip BG */
      /* Default */
      .coolwhip {
        width: 100%;
        bottom: 0;
        position: fixed;
        height: 100vh;
        transform: translateY(25%);
      }
      .coolwhip svg:nth-child(1) {
        opacity: 0.3;
        filter: blur(21.1px);
      }
      .coolwhip svg:nth-child(2) {
        opacity: 0.3;
        filter: blur(21.3px);
      }
      .coolwhip svg:nth-child(3) {
        opacity: 0.3;
        filter: blur(19.3px);
      }

      /* Safari */
      _::-webkit-full-page-media,
      _:future,
      :root .coolwhip {
        filter: blur(68px);
      }

      /* Firefox */
      @-moz-document url-prefix() {
        .coolwhip svg:nth-child(1) {
          filter: blur(211px);
        }
        .coolwhip svg:nth-child(2) {
          filter: blur(213px);
        }
        .coolwhip svg:nth-child(3) {
          filter: blur(193px);
        }
      }
      /* END: Coolwhip BG */

      /* BEGIN: Header */
      /* END: Header */

      /* BEGIN: Containers */
      #okta-login-container {
        display: none; /* hide by default to improve page load */
      }

      #okta-sign-in.auth-container.main-container {
        background: none;
        border: none;
        box-shadow: none;
        width: auto;
        margin: 3rem auto 2rem;
        max-width: 34.375rem;
        font-family: var(--font-circular);
        outline: none;
      }

      /* Override slide/animations */
      #okta-sign-in.auth-container .auth-content {
        padding-top: 1.375rem;
        -moz-transition: none;
        -webkit-transition: none;
        transition: none;
      }

      #okta-sign-in.auth-container
        .auth-content-inner.animation-container-overflow
        > div {
        opacity: 0 !important;
        left: 0 !important;
        width: 100% !important;
      }

      #okta-sign-in.auth-container
        .auth-content-inner.animation-container-overflow
        > div
        + div {
        opacity: 1 !important;
        left: 0 !important;
        width: 100% !important;
      }
      /* END: Containers */

      /* BEGIN: Sign in Header/Beacon */
      #okta-sign-in.auth-container .auth-org-logo {
        height: 40px;
        width: 40px;
      }

      #okta-sign-in.auth-container
        .beacon-container.beacon-small.beacon-loading {
        display: none;
      }

      #okta-sign-in.auth-container .beacon-container {
        bottom: -0.25rem;
        width: 2.813rem;
        height: 2.813rem;
      }

      #okta-sign-in.auth-container .okta-sign-in-beacon-border {
        display: none;
      }

      #okta-sign-in.auth-container .okta-sign-in-header {
        border: none;
        padding: 0;
      }

      #okta-sign-in.auth-container h2.okta-form-title {
        font-style: normal;
        font-weight: 800;
        color: var(--licorice-600);
        font-size: 1.5rem;
        letter-spacing: normal;
        line-height: 2rem;
        margin-bottom: 0.5rem;
      }
      /* END: Sign in Header/Beacon */

      /* BEGIN: Links */
      #okta-sign-in.auth-container .inline-link,
      #okta-sign-in.auth-container .inline-link:active,
      #okta-sign-in.auth-container .inline-link:hover,
      #okta-sign-in.auth-container .inline-link:link,
      #okta-sign-in.auth-container .inline-link:visited {
        color: var(--gumdrop-500);
      }

      #okta-sign-in.auth-container .link:focus {
        outline: auto;
      }
      /* END: Links */

      /* BEGIN: Form */
      #okta-sign-in.auth-container .o-form-content {
        position: relative;
        padding-bottom: 1.375rem;
      }

      #okta-sign-in.auth-container .o-form-explain:not(.okta-form-input-error) {
        font-size: 1rem;
        line-height: 1.5rem;
        color: var(--licorice-400);
      }

      #okta-sign-in.auth-container.main-container .o-form-fieldset {
        margin-bottom: 0;
      }

      #okta-sign-in.auth-container.main-container
        .o-form-fieldset
        + .o-form-fieldset {
        margin-top: 1rem;
      }

      /* Error alert styling */
      #okta-sign-in.auth-container .o-form-has-errors .infobox-error {
        background: var(--julius-50);
        border-radius: 0.375rem;
        color: var(--julius-500);
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.25rem;
        border: none;
        margin-bottom: 1.5rem;
        padding-left: 1.25rem !important;
      }

      #okta-sign-in.auth-container .o-form-has-errors .infobox-error:before {
        background: var(--julius-200);
        width: 0.25rem;
        left: 0;
        top: 0;
        bottom: 0;
      }

      #okta-sign-in.auth-container
        .o-form-has-errors
        .infobox-error
        span.icon.error-16 {
        display: none;
      }

      /* Label */
      #okta-sign-in.auth-container .okta-form-label {
        font-weight: 600;
        line-height: 1.25rem;
        color: var(--licorice-400);
        margin: 0;
        padding: 0 0 0.25rem 0;
      }

      /* Input */
      #okta-sign-in.auth-container .okta-form-input-field {
        border: none;
        padding: 0;
        height: auto;
        display: block;
        float: none;
      }

      #okta-sign-in.auth-container .o-form .input-fix input[type='number'],
      #okta-sign-in.auth-container .o-form .input-fix input[type='password'],
      #okta-sign-in.auth-container .o-form .input-fix input[type='tel'],
      #okta-sign-in.auth-container .o-form .input-fix input[type='text'],
      #okta-sign-in.auth-container .o-form .input-fix input[type='textbox'] {
        background: var(--licorice-25);
        border-radius: 0.5rem;
        height: 2.5rem;
        margin: 0;
        font-size: 1rem;
        line-height: 1.5rem;
        outline: 0.125rem solid transparent;
        outline-offset: 0.125rem;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        font-family: var(--font-circular);
      }

      #okta-sign-in.auth-container .okta-form-input-field.focused-input {
        background-color: transparent;
        height: auto;
        box-shadow: none;
      }

      #okta-sign-in.auth-container
        .o-form
        .input-fix
        input[type]:focus-visible {
        outline: 0.125rem solid transparent;
        outline-offset: 0.125rem;
        box-shadow: inset 0 0 0 0.125rem var(--gumdrop-300);
      }

      #okta-sign-in.auth-container .o-form .input-fix input[disabled],
      #okta-sign-in.auth-container .o-form .input-fix input[readonly],
      #okta-sign-in.auth-container .o-form .textarea-fix input[disabled],
      #okta-sign-in.auth-container .o-form .textarea-fix input[readonly] {
        box-shadow: none;
      }

      #okta-sign-in.auth-container.main-container
        .o-form-fieldset
        .o-form-input-name-remember {
        padding-top: 0.75rem;
        display: block;
      }

      /* Button */
      #okta-sign-in.auth-container .o-form-button-bar,
      #okta-sign-in.auth-container .o-form-button-bar.focused-input {
        margin: 0;
        padding-bottom: 2.5rem;
      }

      #okta-sign-in.auth-container input[type='button'].button-primary,
      #okta-sign-in.auth-container input[type='submit'].button-primary,
      #okta-sign-in.auth-container .button-primary {
        font-family: var(--font-circular);
        padding: 0;
        height: 2.625rem;
        background: var(--gumdrop-500);
        border: 0;
        border-radius: 0.375rem;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.5rem;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: var(--white);
        box-shadow: none;
        display: flex !important;
        align-items: center;
        justify-content: center;
      }

      #okta-sign-in.auth-container input[type='button']:hover,
      #okta-sign-in.auth-container input[type='submit']:hover,
      #okta-sign-in.auth-container input[type='button']:focus,
      #okta-sign-in.auth-container input[type='submit']:focus,
      #okta-sign-in.auth-container input[type='button']:active,
      #okta-sign-in.auth-container input[type='submit']:active,
      #okta-sign-in.auth-container .button-primary:hover,
      #okta-sign-in.auth-container .button-primary:focus,
      #okta-sign-in.auth-container .button-primary:active,
      #okta-sign-in.auth-container .button.button-primary.link-button-disabled {
        background: var(--gumdrop-400);
        box-shadow: none;
      }

      #okta-sign-in.auth-container .button.button-primary.link-button-disabled {
        opacity: 0.5;
      }

      #okta-sign-in.auth-container input[type='button']:focus,
      #okta-sign-in.auth-container input[type='submit']:focus,
      #okta-sign-in.auth-container .button-primary:focus {
        box-shadow: 0 0 0 0.125rem var(--white),
          0 0 0 0.25rem var(--gumdrop-300);
      }

      #okta-sign-in.auth-container .focused-input,
      #okta-sign-in.auth-container .link.help:focus {
        box-shadow: none;
      }

      /* Error state styling */
      #okta-sign-in.auth-container .o-form-fieldset .o-form-input {
        position: relative;
      }

      #okta-sign-in.auth-container .o-form-fieldset .o-form-input-error {
        padding-top: 0.5rem;
        padding-left: 0;
        position: static;
        color: var(--redhot-500);
      }

      #okta-sign-in.auth-container
        .o-form-fieldset
        .o-form-input-error
        .error-16-small {
        left: auto;
        right: 0.875rem;
        top: 0.75rem;
        width: 1rem;
        height: 1rem;
      }

      #okta-sign-in.auth-container
        .o-form-fieldset
        .o-form-input-error
        .error-16-small:before {
        color: var(--redhot-500);
      }

      /* Custom checkbox/radio */
      #okta-sign-in.auth-container .custom-checkbox label:before {
        content: '';
        background-color: var(--licorice-100);
        display: block;
        width: 1rem;
        height: 1rem;
        border-radius: 0.125rem;
        position: absolute;
        left: 0;
      }

      #okta-sign-in.auth-container .custom-checkbox label.checked:before {
        background-color: var(--apple-500);
        background-position: 0;
        background-repeat: no-repeat;
        background-size: 1rem 1rem;
        background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2020%2020%22%20fill%3D%22%23ffffff%22%20aria-hidden%3D%22true%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20d%3D%22M16.707%205.293a1%201%200%20010%201.414l-8%208a1%201%200%2001-1.414%200l-4-4a1%201%200%20011.414-1.414L8%2012.586l7.293-7.293a1%201%200%20011.414%200z%22%20clip-rule%3D%22evenodd%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E);
      }

      #okta-sign-in.auth-container .custom-checkbox label.focus:before,
      #okta-sign-in.auth-container .custom-radio label.focus:before {
        outline: 0.125rem solid transparent;
        outline-offset: 0.125rem;
        box-shadow: 0 0 0 0.125rem #fff, 0 0 0 0.188rem var(--apple-200),
          0 0 0 0.063rem var(--apple-200);
      }

      #okta-sign-in.auth-container .custom-checkbox label,
      #okta-sign-in.auth-container .custom-radio label,
      #okta-sign-in.auth-container
        .o-form-input-name-remember
        .custom-checkbox
        label {
        color: var(--licorice-400);
        font-size: 1rem;
        box-shadow: none !important;
      }

      #okta-sign-in.auth-container .custom-checkbox label,
      #okta-sign-in.auth-container
        .o-form-input-name-remember
        .custom-checkbox
        label {
        background: none;
      }

      /* BEGIN: Footer links */
      #okta-sign-in.auth-container .auth-footer,
      .help-links-container {
        text-align: center;
        position: relative;
      }

      #okta-sign-in.auth-container ul.help-links {
        display: none !important;
      }

      #okta-sign-in.auth-container .auth-footer .link,
      .help-links-container .link {
        color: var(--gumdrop-500);
        font-weight: 700;
        font-size: 0.75rem;
        line-height: 1rem;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        text-decoration: none;
        float: none;
      }

      /* Custom Help Links (On Sign-In page) */
      .help-links-container {
        display: none;
        margin-top: -3.25rem;
        margin-bottom: 2rem;
        padding: 0 1rem;
      }

      .help-links-container ul {
        margin: 0;
        padding: 0;
        display: inline-block !important;
      }

      .help-links-container ul li {
        margin: 0;
        padding: 0;
        color: var(--licorice-300);
        display: flex;
        flex-direction: column;
        gap: 0.875rem;
        align-items: center;
        justify-content: center;
      }

      .help-links-container ul li + li {
        margin-top: 1.875rem;
      }

      @media (min-width: 640px) {
        .help-links-container ul li {
          flex-direction: row;
        }
      }

      /* BEGIN: Footer links */

      /* BEGIN: Form Header (Sign-in / reset-pw) */
      #okta-sign-in.auth-container .primary-auth h2.okta-form-title,
      #okta-sign-in.auth-container .password-reset h2.okta-form-title {
        font-size: 1.875rem;
        letter-spacing: -0.282727px;
        margin-bottom: 1.5rem;
      }
      /* END: Form Header (Sign-in / reset-pw) */

      /* BEGIN: Sign-in specific */
      #okta-sign-in.auth-container .primary-auth .auth-footer a.help {
        display: none;
      }
      /* END: Sign-in specific */

      /* BEGIN: MFA enroll specific */
      #okta-sign-in .enroll-choices .o-form-error-container,
      #okta-sign-in .enroll-choices .o-form-fieldset-container,
      #okta-sign-in .enroll-choices .o-form-button-bar,
      #okta-sign-in .enroll-totp .o-form-error-container,
      #okta-sign-in .enroll-totp .o-form-fieldset-container,
      #okta-sign-in .enroll-totp .o-form-button-bar,
      #okta-sign-in .barcode-totp .o-form-error-container,
      #okta-sign-in .barcode-totp .o-form-fieldset-container,
      #okta-sign-in .barcode-totp .o-form-button-bar,
      #okta-sign-in .enroll-manual-totp .o-form-error-container,
      #okta-sign-in .enroll-manual-totp .o-form-fieldset,
      #okta-sign-in .enroll-manual-totp .o-form-button-bar,
      #okta-sign-in .activate-totp .o-form-error-container,
      #okta-sign-in .activate-totp .o-form-fieldset-container,
      #okta-sign-in .activate-totp .o-form-button-bar,
      #okta-sign-in .mfa-verify .o-form-error-container,
      #okta-sign-in .mfa-verify .o-form-fieldset-container,
      #okta-sign-in .mfa-verify .o-form-button-bar {
        max-width: 23.75rem;
        margin: 0 auto;
      }

      /* Google auth logo */
      #okta-sign-in.auth-container .mfa-google-auth,
      #okta-sign-in.auth-container .enroll-factor-row .mfa-google-auth,
      #okta-sign-in.auth-container .mfa-google-auth-30,
      #okta-sign-in.auth-container .google-auth-38 {
        background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='48' height='48' rx='24' fill='%23F1F8FF'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M20.4 14.4004C19.0745 14.4004 18 15.4749 18 16.8004V31.2004C18 32.5259 19.0745 33.6004 20.4 33.6004H27.6C28.9255 33.6004 30 32.5259 30 31.2004V16.8004C30 15.4749 28.9255 14.4004 27.6 14.4004H20.4ZM24 31.2004C24.6627 31.2004 25.2 30.6631 25.2 30.0004C25.2 29.3376 24.6627 28.8004 24 28.8004C23.3373 28.8004 22.8 29.3376 22.8 30.0004C22.8 30.6631 23.3373 31.2004 24 31.2004Z' fill='%231894E8'/%3E%3C/svg%3E%0A");
      }

      /* Choose factor */
      #okta-sign-in .enroll-choices .o-form-fieldset-container {
        display: none;
      }

      #okta-sign-in .enroll-choices .enroll-factor-list .list-title {
        display: none;
      }

      #okta-sign-in .enroll-choices .enroll-factor-row {
        position: relative;
      }

      #okta-sign-in.auth-container .enroll-totp {
        visibility: hidden; /* Hidden because we skip over this step in JS below */
      }

      #okta-sign-in.auth-container
        .enroll-totp
        .okta-form-subtitle.o-form-explain {
        text-align: center;
      }

      #okta-sign-in.auth-container
        .enroll-factor-row
        .enroll-factor-icon-container {
        margin-right: 1.125rem;
      }

      #okta-sign-in.auth-container .enroll-factor-row .enroll-factor-icon {
        border: none;
        width: 2.813rem;
        height: 2.813rem;
        background-size: 2.813rem;
      }

      #okta-sign-in .enroll-factor-row .enroll-factor-label {
        font-weight: 500;
        font-size: 1.125rem;
        line-height: 1.75rem;
        color: var(--licorice-600);
      }

      #okta-sign-in .enroll-factor-row .enroll-factor-label + p {
        font-size: 1rem;
        line-height: 1.5rem;
        color: var(--licorice-400);
      }

      #okta-sign-in.auth-container .enroll-factor-row .success-16-gray,
      #okta-sign-in.auth-container .enroll-factor-row .success-16-green {
        /*
        position: absolute;
        top: 0px;
        right: 0px;
        transform: translateY(50%);
        ^^ If multiple enrollments are available, may want to display gray/green checkmark.
        */
        display: none;
      }

      /* Enroll in factor */
      #okta-sign-in.auth-container
        .enroll-totp
        .o-form-input-name-__deviceType__
        > div {
        margin-bottom: 0.875rem;
      }

      #okta-sign-in.auth-container .mfa-verify h2.okta-form-title {
        visibility: hidden; /* Made visible via JS below */
      }

      #okta-sign-in.auth-container .enroll-totp .okta-form-divider {
        border-bottom: 0.125rem solid #f1f0f5;
        padding-top: 0.25rem;
        margin-bottom: 1.25rem;
      }

      #okta-sign-in.auth-container .enroll-totp .auth-footer a.help {
        display: none;
      }

      #okta-sign-in.auth-container .app-download-instructions {
        font-size: 1rem;
        line-height: 1.25rem;
        color: var(--licorice-400);
        margin-bottom: 0.313rem;
        display: flex;
      }

      #okta-sign-in.auth-container .app-download-instructions .app-logo {
        width: 2.188rem;
        height: 2.188rem;
        background-size: 2.188rem;
        margin-right: 0.625rem;
        flex-shrink: 0;
        float: none;
      }

      #okta-sign-in.auth-container .app-download-instructions a {
        font-weight: 500;
      }

      /* Set up TOTP */
      #okta-sign-in.auth-container
        .scan-instructions
        .scan-instructions-details-wrapper {
        float: none;
        margin: 0;
      }

      #okta-sign-in.auth-container
        .scan-instructions
        .scan-instructions-details {
        padding: 0;
        font-size: 1rem;
        line-height: 1.5rem;
        color: var(--licorice-400);
        text-align: center;
      }

      #okta-sign-in.auth-container
        .scan-instructions
        .scan-instructions-qrcode-wrapper {
        float: none;
      }

      #okta-sign-in.auth-container .scan-instructions .qrcode-wrap {
        position: relative;
        display: flex;
        justify-content: center;
      }

      #okta-sign-in.auth-container .qrcode-image {
        border: none;
        width: 10rem;
        height: 10rem;
      }

      #okta-sign-in.auth-container
        .scan-instructions
        .scan-instructions-qrcode-wrapper
        .link,
      #okta-sign-in.auth-container
        .scan-instructions
        .scan-instructions-qrcode-wrapper
        .link:active,
      #okta-sign-in.auth-container
        .scan-instructions
        .scan-instructions-qrcode-wrapper
        .link:hover,
      #okta-sign-in.auth-container
        .scan-instructions
        .scan-instructions-qrcode-wrapper
        .link:link,
      #okta-sign-in.auth-container
        .scan-instructions
        .scan-instructions-qrcode-wrapper
        .link:visited {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5rem;
        color: var(--licorice-600);
      }

      #okta-sign-in.auth-container .enroll-manual-totp .o-form-content {
        padding-bottom: 3.875rem;
      }

      #okta-sign-in .enroll-manual-totp .secret-key-instructions .shared-key {
        background-color: var(--licorice-50);
        border-radius: 0.5rem;
        height: 4.063rem;
        margin: 1.875rem auto 1.25rem;
        padding: 0px 0.5rem;
        color: var(--licorice-600);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 380px;
        box-sizing: border-box;
        word-break: break-all;
      }

      #okta-sign-in
        .enroll-manual-totp
        .secret-key-instructions
        .shared-key:focus {
        box-shadow: none;
        border: 0px;
      }

      #okta-sign-in.auth-container
        .enroll-manual-totp
        .auth-footer
        .link.js-goto,
      #okta-sign-in.auth-container .mfa-verify .auth-footer .link.goto {
        float: none;
      }

      #okta-sign-in.auth-container .activate-totp .o-form-fieldset-container,
      #okta-sign-in.auth-container .mfa-verify .o-form-fieldset-container {
        padding: 2.5rem 0;
      }

      #okta-sign-in.auth-container
        .enroll-manual-totp
        .auth-footer
        .link.js-goto {
        position: absolute;
        font-size: 1rem;
        line-height: 1.5rem;
        color: var(--licorice-600);
        text-transform: none;
        letter-spacing: normal;
        font-weight: 400;
        top: -8.938rem;
        left: 50%;
        transform: translateX(-50%);
      }

      #okta-sign-in.auth-container
        .enroll-manual-totp
        .auth-footer
        .link.js-goto:hover {
        text-decoration: underline;
      }
      /* END: MFA enroll specific */

      /* BEGIN: Forgot Password */
      #okta-sign-in.auth-container .forgot-password h2.okta-form-title {
        margin-bottom: 74px;
      }

      #okta-sign-in.auth-container .forgot-password .o-form-fieldset-container {
        padding: 2.5rem 0 1.5rem;
      }

      #okta-sign-in.auth-container .forgot-password .o-form-explain,
      #okta-sign-in.auth-container .password-reset-email-sent .o-form-explain {
        padding-top: 1.375rem;
      }

      #okta-sign-in.auth-container .forgot-password .o-form-explain.absolute {
        position: absolute;
        top: 50px;
        width: 100%;
      }

      #okta-sign-in.auth-container .forgot-password .button-primary {
        margin-top: 2.5rem;
      }

      #okta-sign-in.auth-container
        .password-reset-email-sent
        .o-form-fieldset-container {
        text-align: center;
      }

      #okta-sign-in.auth-container .password-reset-email-sent .button-primary {
        display: inline-block;
        color: var(--gumdrop-500);
        font-weight: 700;
        font-size: 0.75rem;
        line-height: 1rem;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        text-decoration: none;
        height: auto;
        background: none !important;
        box-shadow: none !important;
        width: auto !important;
        margin: 1.5rem auto 0;
      }

      #okta-sign-in.auth-container
        .password-reset-email-sent
        .button-primary:focus {
        outline: auto;
      }
      /* END: Forgot Password */

      /* BEGIN: Reset Password Form */
      #okta-sign-in.auth-container
        .password-reset
        .password-requirements--header {
        font-weight: 600;
      }
      #okta-sign-in.auth-container
        .password-reset
        .password-requirements--list {
        padding-bottom: 1.25rem;
      }
      #okta-sign-in.auth-container .password-reset .button-primary {
        margin-top: 1.125rem;
      }
      /* END: Reset Password Form */

      /* ### END: Circle brand CSS overrides ### */

      