.limpar-botao:hover {
background: transparent;

}

.limpar-botao:focus {
    background: transparent;
    border:0px solid;
    
    }
    .limpar-botao-2:focus {
        background: transparent;
        border:0px solid;
        color: #00dc84;
        
        }
    

.icon-right::before {
    content:none !important;
}