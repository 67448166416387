.root {
    flex: 1;
    align-self: stretch;
    flex-direction: column;
    gap: 20px;
    padding: 27px 0;
    background-color: #f9f9fc;
  }
  .header {
    height: min-content;
    align-self: stretch;
    flex-direction: column;
    padding: 0 20px;
  }
  .divCbHeaderHead {
    height: min-content;
    align-self: stretch;
    place-content: space-between;
    align-items: center;
  }
  .heading1Home {
    color: #08383f;
    font-size: 32px;
    line-height: 54px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.75px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .divActions {
    width: min-content;
    height: min-content;
    place-content: flex-end;
    align-items: flex-start;
    gap: 20px;
    padding: 9px 0;
  }
  .button {
    width: min-content;
    height: min-content;
    padding: 10.25px 17.44px 11.75px 18px;
    border-radius: 6.75px;
    background-color: #08383f;
  }
  .tRANSFER {
    color: #fff;
    font-size: 15.75px;
    line-height: 22.5px;
    font-weight: bold;
    font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: 0.3938px;
    text-transform: uppercase;
    width: min-content;
    height: min-content;
    text-align: center;
    align-items: center;
    white-space: nowrap;
    flex-direction: column;
  }
  .button2 {
    width: min-content;
    height: min-content;
    padding: 10.25px 17.44px 11.75px 18px;
    border-radius: 6.75px;
    background-color: #08383f;
  }
  .dEPOSIT {
    color: #fff;
    font-size: 15.75px;
    line-height: 22.5px;
    font-weight: bold;
    font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: 0.3938px;
    text-transform: uppercase;
    width: min-content;
    height: min-content;
    text-align: center;
    align-items: center;
    white-space: nowrap;
    flex-direction: column;
  }
  .frame25 {
    height: min-content;
    align-self: stretch;
    flex-direction: column;
    padding: 0 20px;
  }
  .section {
    height: min-content;
    align-self: stretch;
    flex-direction: column;
    place-content: center;
    padding: 10px 0;
    border-radius: 18px;
    background-color: #fff;
    overflow: hidden;
    box-shadow: 0px 2px 4px -2px #0000001a, 0px 4px 6px -1px #0000001a;
  }
  .divContent {
    height: min-content;
    align-self: stretch;
    flex-direction: column;
    padding: 10px 20px;
  }
  .divFlex {
    height: min-content;
    align-self: stretch;
    align-items: center;
    padding: 0 0 9px;
  }
  .frame24 {
    height: min-content;
    flex: 1;
  }
  .heading3RecentActivity {
    color: #08383f;
    font-size: 24px;
    line-height: 36px;
    font-weight: 500;
    font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.2px;
    height: min-content;
    flex: 1;
    flex-direction: column;
  }
  .link {
    height: min-content;
    flex: 1;
    place-content: flex-end;
    align-items: center;
    gap: 5px;
  }
  .viewAll {
    color: #022c74;
    font-size: 16px;
    line-height: 27px;
    font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .img {
    position: relative;
    width: 16px;
    height: 16px;
    align-items: flex-start;
    overflow: hidden;
  }
  .frame {
    position: absolute;
    left: 50%;
    top: 0;
    width: 16px;
    height: 16px;
    overflow: visible;
    transform: translateX(-50%);
  }
  .icon {
    width: 100%;
    height: 100%;
  }
  .frame6 {
    height: min-content;
    align-self: stretch;
    align-items: flex-start;
    gap: 30px;
    padding: 0 10px;
  }
  .frame2 {
    height: min-content;
    flex: 1;
    padding: 10px;
  }
  .identification {
    color: #08383f;
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .frame5 {
    height: min-content;
    flex: 1;
    padding: 10px;
  }
  .date {
    color: #08383f;
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .frame62 {
    height: min-content;
    flex: 1;
    padding: 10px;
  }
  .status {
    color: #08383f;
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .frame3 {
    height: min-content;
    flex: 1;
    padding: 10px;
  }
  .amount {
    color: #08383f;
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .frame4 {
    height: min-content;
    flex: 1;
    padding: 10px;
  }
  .description {
    color: #08383f;
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .frame52 {
    height: 367px;
    align-self: stretch;
    flex-direction: column;
    gap: 20px;
    padding: 0 10px;
  }
  .frame11 {
    height: min-content;
    align-self: stretch;
    align-items: flex-start;
    gap: 30px;
  }
  .frame22 {
    height: min-content;
    flex: 1;
    padding: 10px;
  }
  .matheus452957327 {
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .textBlock {
    color: #08383f;
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
  }
  .textBlock2 {
    margin-top: 0;
  }
  .labelWrapper {
    font-size: 12px;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    flex-shrink: 1;
  }
  .label {
    color: #08383f;
    font-size: 12px;
    line-height: 27px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
  }
  .frame53 {
    height: min-content;
    flex: 1;
    padding: 10px;
  }
  .jul32023345PM {
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .textBlock3 {
    color: #08383f;
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
  }
  .textBlock4 {
    margin-top: 0;
  }
  .labelWrapper2 {
    font-size: 12px;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    flex-shrink: 1;
  }
  .label2 {
    color: #08383f;
    font-size: 12px;
    line-height: 27px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
  }
  .frame63 {
    height: min-content;
    flex: 1;
    padding: 10px;
  }
  .pending {
    color: #ecbe1a;
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .frame32 {
    height: min-content;
    flex: 1;
    padding: 10px;
  }
  ._3528BRL {
    color: #00dc84;
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .frame42 {
    height: min-content;
    flex: 1;
    padding: 10px;
  }
  .pIXInFromUserMatheus {
    color: #08383f;
    font-size: 12px;
    line-height: 27px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .frame12 {
    height: min-content;
    align-self: stretch;
    align-items: flex-start;
    gap: 30px;
  }
  .frame23 {
    height: min-content;
    flex: 1;
    padding: 10px;
  }
  .matheus4529573272 {
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .textBlock5 {
    color: #08383f;
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
  }
  .textBlock6 {
    margin-top: 0;
  }
  .labelWrapper3 {
    font-size: 12px;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    flex-shrink: 1;
  }
  .label3 {
    color: #08383f;
    font-size: 12px;
    line-height: 27px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
  }
  .frame54 {
    height: min-content;
    flex: 1;
    padding: 10px;
  }
  .jul32023345PM2 {
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .textBlock7 {
    color: #08383f;
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
  }
  .textBlock8 {
    margin-top: 0;
  }
  .labelWrapper4 {
    font-size: 12px;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    flex-shrink: 1;
  }
  .label4 {
    color: #08383f;
    font-size: 12px;
    line-height: 27px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
  }
  .frame64 {
    height: min-content;
    flex: 1;
    padding: 10px;
  }
  .completed {
    color: #00dc84;
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .frame33 {
    height: min-content;
    flex: 1;
    padding: 10px;
  }
  ._3528BRL2 {
    color: #00dc84;
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .frame43 {
    height: min-content;
    flex: 1;
    padding: 10px;
  }
  .pIXInFromUserMatheus2 {
    color: #08383f;
    font-size: 12px;
    line-height: 27px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .frame13 {
    height: min-content;
    align-self: stretch;
    align-items: flex-start;
    gap: 30px;
  }
  .frame26 {
    height: min-content;
    flex: 1;
    padding: 10px;
  }
  .matheus4529573273 {
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .textBlock9 {
    color: #08383f;
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
  }
  .textBlock10 {
    margin-top: 0;
  }
  .labelWrapper5 {
    font-size: 12px;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    flex-shrink: 1;
  }
  .label5 {
    color: #08383f;
    font-size: 12px;
    line-height: 27px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
  }
  .frame55 {
    height: min-content;
    flex: 1;
    padding: 10px;
  }
  .jul32023345PM3 {
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .textBlock11 {
    color: #08383f;
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
  }
  .textBlock12 {
    margin-top: 0;
  }
  .labelWrapper6 {
    font-size: 12px;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    flex-shrink: 1;
  }
  .label6 {
    color: #08383f;
    font-size: 12px;
    line-height: 27px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
  }
  .frame65 {
    height: min-content;
    flex: 1;
    padding: 10px;
  }
  .canceled {
    color: #f8564b;
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .frame34 {
    height: min-content;
    flex: 1;
    padding: 10px;
  }
  ._3528BRL3 {
    color: #00dc84;
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .frame44 {
    height: min-content;
    flex: 1;
    padding: 10px;
  }
  .pIXInFromUserMatheus3 {
    color: #08383f;
    font-size: 12px;
    line-height: 27px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .frame14 {
    height: min-content;
    align-self: stretch;
    align-items: flex-start;
    gap: 30px;
  }
  .frame27 {
    height: min-content;
    flex: 1;
    padding: 10px;
  }
  .matheus4529573274 {
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .textBlock13 {
    color: #08383f;
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
  }
  .textBlock14 {
    margin-top: 0;
  }
  .labelWrapper7 {
    font-size: 12px;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    flex-shrink: 1;
  }
  .label7 {
    color: #08383f;
    font-size: 12px;
    line-height: 27px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
  }
  .frame56 {
    height: min-content;
    flex: 1;
    padding: 10px;
  }
  .jul32023345PM4 {
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .textBlock15 {
    color: #08383f;
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
  }
  .textBlock16 {
    margin-top: 0;
  }
  .labelWrapper8 {
    font-size: 12px;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    flex-shrink: 1;
  }
  .label8 {
    color: #08383f;
    font-size: 12px;
    line-height: 27px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
  }
  .frame66 {
    height: min-content;
    flex: 1;
    padding: 10px;
  }
  .completed2 {
    color: #00dc84;
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .frame35 {
    height: min-content;
    flex: 1;
    padding: 10px;
  }
  ._6028BRL {
    color: #f8564b;
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .frame45 {
    height: min-content;
    flex: 1;
    padding: 10px;
  }
  .pIXOutFromUserMatheus {
    color: #08383f;
    font-size: 12px;
    line-height: 27px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .frame7 {
    height: 367px;
    align-self: stretch;
    flex-direction: column;
    gap: 20px;
    padding: 0 10px;
  }
  .frame112 {
    height: min-content;
    align-self: stretch;
    align-items: flex-start;
    gap: 30px;
  }
  .frame28 {
    height: min-content;
    flex: 1;
    padding: 10px;
  }
  .matheus4529573275 {
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .textBlock17 {
    color: #08383f;
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
  }
  .textBlock18 {
    margin-top: 0;
  }
  .labelWrapper9 {
    font-size: 12px;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    flex-shrink: 1;
  }
  .label9 {
    color: #08383f;
    font-size: 12px;
    line-height: 27px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
  }
  .frame57 {
    height: min-content;
    flex: 1;
    padding: 10px;
  }
  .jul32023345PM5 {
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .textBlock19 {
    color: #08383f;
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
  }
  .textBlock20 {
    margin-top: 0;
  }
  .labelWrapper10 {
    font-size: 12px;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    flex-shrink: 1;
  }
  .label10 {
    color: #08383f;
    font-size: 12px;
    line-height: 27px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
  }
  .frame67 {
    height: min-content;
    flex: 1;
    padding: 10px;
  }
  .pending2 {
    color: #ecbe1a;
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .frame36 {
    height: min-content;
    flex: 1;
    align-items: center;
    gap: 10px;
    padding: 10px;
  }
  ._3528BRL475USDTBRL {
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .textBlock21 {
    color: #00dc84;
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
  }
  .textBlock22 {
    margin-top: 0;
  }
  .labelWrapper11 {
    font-size: 10px;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    flex-shrink: 1;
  }
  .label11 {
    color: #08383f;
    font-size: 10px;
    line-height: 27px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
  }
  .mdiRecurringPayment {
    width: 30px;
    height: 30px;
  }
  .icon2 {
    width: 100%;
    height: 100%;
  }
  .frame46 {
    height: min-content;
    flex: 1;
    padding: 10px;
  }
  .pIXInFromUserMatheus4 {
    color: #08383f;
    font-size: 12px;
    line-height: 27px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .frame122 {
    height: min-content;
    align-self: stretch;
    align-items: flex-start;
    gap: 30px;
  }
  .frame29 {
    height: min-content;
    flex: 1;
    padding: 10px;
  }
  .matheus4529573276 {
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .textBlock23 {
    color: #08383f;
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
  }
  .textBlock24 {
    margin-top: 0;
  }
  .labelWrapper12 {
    font-size: 12px;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    flex-shrink: 1;
  }
  .label12 {
    color: #08383f;
    font-size: 12px;
    line-height: 27px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
  }
  .frame58 {
    height: min-content;
    flex: 1;
    padding: 10px;
  }
  .jul32023345PM6 {
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .textBlock25 {
    color: #08383f;
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
  }
  .textBlock26 {
    margin-top: 0;
  }
  .labelWrapper13 {
    font-size: 12px;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    flex-shrink: 1;
  }
  .label13 {
    color: #08383f;
    font-size: 12px;
    line-height: 27px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
  }
  .frame68 {
    height: min-content;
    flex: 1;
    padding: 10px;
  }
  .completed3 {
    color: #00dc84;
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .frame37 {
    height: min-content;
    flex: 1;
    padding: 10px;
  }
  ._3528BRL4 {
    color: #00dc84;
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .frame47 {
    height: min-content;
    flex: 1;
    padding: 10px;
  }
  .pIXInFromUserMatheus5 {
    color: #08383f;
    font-size: 12px;
    line-height: 27px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .frame132 {
    height: min-content;
    align-self: stretch;
    align-items: flex-start;
    gap: 30px;
  }
  .frame210 {
    height: min-content;
    flex: 1;
    padding: 10px;
  }
  .matheus4529573277 {
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .textBlock27 {
    color: #08383f;
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
  }
  .textBlock28 {
    margin-top: 0;
  }
  .labelWrapper14 {
    font-size: 12px;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    flex-shrink: 1;
  }
  .label14 {
    color: #08383f;
    font-size: 12px;
    line-height: 27px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
  }
  .frame59 {
    height: min-content;
    flex: 1;
    padding: 10px;
  }
  .jul32023345PM7 {
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .textBlock29 {
    color: #08383f;
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
  }
  .textBlock30 {
    margin-top: 0;
  }
  .labelWrapper15 {
    font-size: 12px;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    flex-shrink: 1;
  }
  .label15 {
    color: #08383f;
    font-size: 12px;
    line-height: 27px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
  }
  .frame69 {
    height: min-content;
    flex: 1;
    padding: 10px;
  }
  .canceled2 {
    color: #f8564b;
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .frame38 {
    height: min-content;
    flex: 1;
    padding: 10px;
  }
  ._3528BRL5 {
    color: #00dc84;
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .frame48 {
    height: min-content;
    flex: 1;
    padding: 10px;
  }
  .pIXInFromUserMatheus6 {
    color: #08383f;
    font-size: 12px;
    line-height: 27px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .frame142 {
    height: min-content;
    align-self: stretch;
    align-items: flex-start;
    gap: 30px;
  }
  .frame211 {
    height: min-content;
    flex: 1;
    padding: 10px;
  }
  .matheus4529573278 {
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .textBlock31 {
    color: #08383f;
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
  }
  .textBlock32 {
    margin-top: 0;
  }
  .labelWrapper16 {
    font-size: 12px;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    flex-shrink: 1;
  }
  .label16 {
    color: #08383f;
    font-size: 12px;
    line-height: 27px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
  }
  .frame510 {
    height: min-content;
    flex: 1;
    padding: 10px;
  }
  .jul32023345PM8 {
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .textBlock33 {
    color: #08383f;
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
  }
  .textBlock34 {
    margin-top: 0;
  }
  .labelWrapper17 {
    font-size: 12px;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    flex-shrink: 1;
  }
  .label17 {
    color: #08383f;
    font-size: 12px;
    line-height: 27px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
  }
  .frame610 {
    height: min-content;
    flex: 1;
    padding: 10px;
  }
  .completed4 {
    color: #00dc84;
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .frame39 {
    height: min-content;
    flex: 1;
    padding: 10px;
  }
  ._6028BRL2 {
    color: #f8564b;
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .frame49 {
    height: min-content;
    flex: 1;
    padding: 10px;
  }
  .pIXOutFromUserMatheus2 {
    color: #08383f;
    font-size: 12px;
    line-height: 27px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .footer {
    flex: 1;
    align-self: stretch;
    align-items: flex-end;
    padding: 0 20px;
  }
  ._2023BRLADigitalAllRightsReserv {
    color: #08383f;
    font-size: 13.5px;
    line-height: 18px;
    font-weight: 500;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    height: min-content;
    flex: 1;
    flex-direction: column;
  }
  .divMlAuto {
    height: min-content;
    flex: 1;
    place-content: flex-end;
    align-items: center;
    gap: 9px;
  }
  .linkTermsOfUse {
    color: #022c74;
    font-size: 12.5px;
    line-height: 18px;
    font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .linkPrivacyPolicy {
    color: #022c74;
    font-size: 12.5px;
    line-height: 18px;
    font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .linkCookiePolicy {
    color: #022c74;
    font-size: 12.5px;
    line-height: 18px;
    font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: -0.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  

  .description {
    /* Frame 4 */

/* Auto layout */
display: flex;
flex-direction: row;
align-items: center;
padding: 10px;
gap: 10px;

width: 252px;
height: 47px;


/* Inside auto layout */
flex: none;
order: 4;
flex-grow: 1;


/* PIX-Out from User Matheus */

width: 159px;
height: 27px;

font-family: 'Gordita';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 27px;
/* identical to box height, or 225% */
display: flex;
align-items: center;
letter-spacing: -0.4px;

color: #08383F;


/* Inside auto layout */
flex: none;
order: 0;
flex-grow: 0;

  }