  .root {
  width: 100%;
  height: min-content;
  flex-direction: column;
}
.headerHeader {
  height: min-content;
  align-self: stretch;
  align-items: flex-start;
  gap: 20px;
  padding: 6px 0;
  background-image: linear-gradient(90deg, #0a1b23ff 0%, #08383fff 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  overflow: hidden;
}
.logo {
  width: 313.3988px;
  height: 70px;
  background-image: url('../../../public/assets/logo.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.ulMainNavigation {
  flex: 1;
  align-self: stretch;
  place-content: flex-end;
  align-items: center;
  gap: 15px;
  padding: 0 45px 1px;
}
.home {
  color: #fff;
  font-size: 13px;
  line-height: 15px;
  font-weight: bold;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  width: fit-content;
  height: min-content;
  flex-direction: column;
}
.home:hover {
  color: #00dc84;
  transition: 0.2s;
}
.about {
  color: #fff;
  font-size: 13px;
  line-height: 15px;
  font-weight: bold;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  width: fit-content;
  height: min-content;
  flex-direction: column;
}
.about:hover {
  color: #00dc84;
  transition: 0.2s;
}
.login {
  color: #fff;
  font-size: 13px;
  line-height: 15px;
  font-weight: bold;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  width: fit-content;
  height: min-content;
  flex-direction: column;
}
.login:hover {
  color: #00dc84;
  transition: 0.2s;
}
.sIGNUP {
  color: #fff;
  font-size: 13px;
  line-height: 15px;
  font-weight: bold;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  width: fit-content;
  height: min-content;
  flex-direction: column;
}
.sIGNUP:hover {
  color: #00dc84;
  transition: 0.2s;
}
.aPIREFERENCE {
  color: #fff;
  font-size: 13px;
  line-height: 15px;
  font-weight: bold;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  width: fit-content;
  height: min-content;
  flex-direction: column;
}
.aPIREFERENCE:hover {
  color: #00dc84;
  transition: 0.2s;
}
.cONTACT {
  color: #fff;
  font-size: 13px;
  line-height: 15px;
  font-weight: bold;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  width: fit-content;
  height: min-content;
  flex-direction: column;
}
.cONTACT:hover {
  color: #00dc84;
  transition: 0.2s;
}
.section1 {
  height: min-content;
  align-self: stretch;
  padding: 30px 44px;
  background-image: linear-gradient(90deg, #0a1b23ff 0%, #08383fff 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
}
.frame21 {
  height: min-content;
  flex: 1;
  align-self: flex-start;
  align-items: center;
  gap: 50px;
}
.frame19 {
  height: min-content;
  flex: 1;
  flex-direction: column;
  gap: 15px;
}
.brazilianFinancialInfrastructu {
  color: #fff;
  font-size: 48px;
  line-height: 52px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.weHaveDevelopedFinancialSoluti {
  color: #fff;
  font-size: 16.8px;
  line-height: 21.84px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  height: min-content;
  align-self: stretch;
  text-align: justify;
  flex-direction: column;
}
.frame18 {
  height: min-content;
  align-self: stretch;
  align-items: flex-start;
  gap: 15px;
  overflow: hidden;
}
.frame2 {
  height: 50px;
  flex: 1;
  padding: 10px;
  outline: solid 3px #008884;
  outline-offset: -3px;
  border-radius: 5px;
}
.checkOurSDKDocumentation {
  color: #fff;
  font-size: 16px;
  line-height: 13px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  z-index: 0;
  flex: 1;
  align-self: stretch;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.frame1 {
  height: 50px;
  flex: 1;
  padding: 10px;
  outline: solid 3px #008884;
  outline-offset: -3px;
  border-radius: 5px;
}
.openYourAccount {
  color: #fff;
  font-size: 16px;
  line-height: 13px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  z-index: 0;
  flex: 1;
  align-self: stretch;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.frame3 {
  height: 50px;
  flex: 1;
  padding: 10px;
  outline: solid 3px #008884;
  outline-offset: -3px;
  border-radius: 5px;
}
.contactUs {
  color: #fff;
  font-size: 16px;
  line-height: 13px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  z-index: 0;
  flex: 1;
  align-self: stretch;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.frame20 {
  position: relative;
  height: 447px;
  flex: 1;
  align-items: center;
}
.tela_home {
  position: absolute;
  left: calc(50% - -14.1818px);
  top: calc(50% - 100px);
  width: 369.3636px;
  height: 200px;
  outline: solid 1px #00dc84;
  outline-offset: -1px;
  border-radius: 5px;
  background-image: url('../../../public/assets/tela_home.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: translate(-50%, -50%);
}
.tela_signup {
  position: absolute;
  left: calc(50% - 170.2036px);
  top: calc(50% - -75px);
  width: 156.5928px;
  height: 200px;
  outline: solid 1px #00dc84;
  outline-offset: -1px;
  border-radius: 5px;
  background-image: url('../../../public/assets/tela_signup.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: translate(-50%, -50%);
}
.tela_swap {
  position: absolute;
  left: calc(50% - -59.1675px);
  top: calc(50% - -100px);
  width: 375.1082px;
  height: 200px;
  outline: solid 1px #00dc84;
  outline-offset: -1px;
  border-radius: 5px;
  background-image: url('../../../public/assets/tela_swap.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: translate(-50%, -50%);
}
.section2 {
  height: min-content;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 30px 44px;
  background-color: #fff;
}
.frame22 {
  height: min-content;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
}
.onOffRampPoweredByBRLA {
  color: #0a1b23;
  font-size: 40px;
  line-height: 50px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.byUsingOurSDKGlobalCompaniesCa {
  color: #0a1b23;
  font-size: 16.8px;
  line-height: 21.84px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  height: min-content;
  align-self: stretch;
  text-align: justify;
  flex-direction: column;
}
.frame12 {
  width: min-content;
  height: 50px;
  padding: 10px;
  outline: solid 3px #008884;
  outline-offset: -3px;
  border-radius: 5px;
}
.aPIReference {
  color: #0a1b23;
  font-size: 16px;
  line-height: 13px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  z-index: 0;
  width: 231px;
  align-self: stretch;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.frame23 {
  height: min-content;
  flex: 1;
  place-content: center;
  align-items: center;
  gap: 50px;
}
.mint_BRLA {
  width: 250.5353px;
  height: 300px;
  outline: solid 1px #00dc84;
  outline-offset: -1px;
  border-radius: 5px;
  background-image: url('../../../public/assets/mint_BRLA.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.qR_Code {
  width: 212px;
  height: 300px;
  outline: solid 1px #00dc84;
  outline-offset: -1px;
  border-radius: 5px;
  background-image: url('../../../public/assets/qR_Code.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.section3 {
  height: min-content;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 30px 44px;
  background-color: #0a1b23;
}
.frame25 {
  height: min-content;
  flex: 1;
}
.swap {
  width: 318.8588px;
  height: 350px;
  outline: solid 1px #00dc84;
  outline-offset: -1px;
  border-radius: 5px;
  background-image: url('../../../public/assets/swap.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.frame24 {
  height: min-content;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
}
.createProductsInBRLA {
  color: #fff;
  font-size: 40px;
  line-height: 50px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.globalCompaniesCanUseOurSDKToI {
  color: #fff;
  font-size: 16.8px;
  line-height: 21.84px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  height: min-content;
  align-self: stretch;
  text-align: justify;
  flex-direction: column;
}
.frame13 {
  width: fit-content;
  height: 50px;
  padding: 10px;
  outline: solid 3px #008884;
  outline-offset: -3px;
  border-radius: 5px;
}
.aPIReference2 {
  color: #fff;
  font-size: 16px;
  line-height: 13px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  z-index: 0;
  width: 231px;
  align-self: stretch;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.section4 {
  height: min-content;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 75px 44px;
  background-color: #fff;
}
.frame27 {
  height: min-content;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
}
.bRLAAccount {
  color: #0a1b23;
  font-size: 40px;
  line-height: 50px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.byHavingABRLAAccountGlobalComp {
  height: min-content;
  align-self: stretch;
  text-align: justify;
  flex-direction: column;
}
.textBlock {
  color: #0a1b23;
  font-size: 16.8px;
  line-height: 21.84px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
}
.list {
  font-size: 16.8px;
}
.list ::marker {
  color: #0a1b23;
  font-size: 16.8px;
  line-height: 21.84px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
}
.textBlock2 {
  margin-top: 0;
  color: #0a1b23;
  font-size: 16.8px;
  line-height: 21.84px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
}
.labelWrapper {
  font-size: 16.8px;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  flex-shrink: 1;
}
.label {
  color: #0a1b23;
  font-size: 16.8px;
  line-height: 21.84px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
}
.textBlock3 {
  margin-top: 0;
  color: #0a1b23;
  font-size: 16.8px;
  line-height: 21.84px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
}
.textBlock4 {
  margin-top: 0;
  color: #0a1b23;
  font-size: 16.8px;
  line-height: 21.84px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
}
.frame26 {
  width: min-content;
  height: min-content;
  align-items: flex-start;
  gap: 15px;
}
.frame14 {
  width: 251px;
  height: 50px;
  padding: 10px;
  outline: solid 3px #008884;
  outline-offset: -3px;
  border-radius: 5px;
}
.openYourAccount2 {
  color: #0a1b23;
  font-size: 16px;
  line-height: 13px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  z-index: 0;
  flex: 1;
  align-self: stretch;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.frame28 {
  width: 251px;
  height: 50px;
  padding: 10px;
  outline: solid 3px #008884;
  outline-offset: -3px;
  border-radius: 5px;
}
.contactUs2 {
  color: #0a1b23;
  font-size: 16px;
  line-height: 13px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  z-index: 0;
  flex: 1;
  align-self: stretch;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.frame282 {
  height: min-content;
  flex: 1;
  place-content: center;
}
.tela_home2 {
  width: 572.5189px;
  height: 250px;
  outline: solid 1px #00dc84;
  outline-offset: -1px;
  border-radius: 5px;
  background-image: url('../../../public/assets/tela_home2.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.section5 {
  position: relative;
  height: min-content;
  align-self: stretch;
  align-items: center;
  padding: 11px 31px 30px;
  overflow: hidden;
  background-color: #0a1b23;
  z-index: 1;
}

.section5::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../../public/assets/section5.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.2;
  z-index: -1;
}

.frame29 {
  height: min-content;
  flex: 1;
  padding: 10px;
}
.tela_signup2 {
  width: 274.0373px;
  height: 350px;
  outline: solid 1px #00dc84;
  outline-offset: -1px;
  border-radius: 5px;
  background-image: url('../../../public/assets/tela_signup2.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.frame30 {
  height: min-content;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 29px;
}
.areYouAnIndividual {
  color: #fff;
  font-size: 40px;
  line-height: 50px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.youCanAlsoCreateYourBRLAAccoun {
  color: #fff;
  font-size: 16.8px;
  line-height: 21.84px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  height: min-content;
  align-self: stretch;
  text-align: justify;
  flex-direction: column;
}
.frame15 {
  width: 251px;
  height: 50px;
  padding: 10px;
  outline: solid 3px #008884;
  outline-offset: -3px;
  border-radius: 5px;

}
.openYourAccount3 {
  color: #fff;
  font-size: 16px;
  line-height: 13px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.36px;
  z-index: 0;
  flex: 1;
  align-self: stretch;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.footerFooter {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  gap: 42px;
  padding: 30px 44px;
  background-image: linear-gradient(90deg, #0a1b23ff 0%, #08383fff 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
}
.contact {
  color: #fff;
  font-size: 40px;
  line-height: 48px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.75px;
  height: min-content;
  align-self: stretch;
  text-align: center;
  align-items: center;
  flex-direction: column;
}
.divRow {
  height: min-content;
  align-self: stretch;
  align-items: center;
  gap: 50px;
}
.form {
  height: min-content;
  flex: 1;
  flex-direction: column;
  place-content: center;
  align-items: flex-end;
  gap: 11px;
}
.frame32 {
  width: 500px;
  height: min-content;
  align-items: center;
  gap: 15px;
}
.inputMobileWidth {
  height: min-content;
  flex: 1;
  padding: 9px 10px;
  border-radius: 10px;
  background-color: #fff;
}
.divPlaceholder {
  height: min-content;
  flex: 1;
  align-self: flex-start;
  overflow: hidden;
}
.name {
  color: #9ca3af;
  font-size: 14px;
  font-family: IBM Plex Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  height: min-content;
  flex: 1;
  flex-direction: column;
}
.inputMobileWidth2 {
  height: min-content;
  flex: 1;
  padding: 9px 10px;
  border-radius: 10px;
  background-color: #fff;
}
.divPlaceholder2 {
  height: min-content;
  flex: 1;
  align-self: flex-start;
  overflow: hidden;
}
.eMail {
  color: #9ca3af;
  font-size: 14px;
  font-family: IBM Plex Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  height: min-content;
  flex: 1;
  flex-direction: column;
}
.frame33 {
  width: 500px;
  height: min-content;
  align-items: flex-start;
  gap: 15px;
}
.inputMobileWidth3 {
  height: min-content;
  flex: 1;
  padding: 9px 10px;
  border-radius: 10px;
  background-color: #fff;
}
.divPlaceholder3 {
  height: min-content;
  flex: 1;
  align-self: flex-start;
  overflow: hidden;
}
.phone {
  color: #9ca3af;
  font-size: 14px;
  font-family: IBM Plex Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  height: min-content;
  flex: 1;
  flex-direction: column;
}
.inputMobileWidth4 {
  height: min-content;
  flex: 1;
  padding: 9px 10px;
  border-radius: 10px;
  background-color: #fff;
}
.divPlaceholder4 {
  height: min-content;
  flex: 1;
  align-self: flex-start;
  overflow: hidden;
}
.company {
  color: #9ca3af;
  font-size: 14px;
  font-family: IBM Plex Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  height: min-content;
  flex: 1;
  flex-direction: column;
}
.textarea {
  width: 500px;
  height: 200px;
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;
  overflow: hidden;
}
.frame34 {
  width: 500px;
  height: min-content;
  flex-direction: column;
  align-items: flex-start;
}
.button {
  width: min-content;
  height: min-content;
  padding: 10px 10.67px 10px 9px;
  border-radius: 10px;
  background-color: #00dc84;
}
.submit {
  color: #fff;
  font-size: 14px;
  line-height: 14px;
  font-weight: bold;
  font-family: IBM Plex Sans, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  text-transform: uppercase;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
}
.divHs_cos_wrapper_module_16619 {
  height: min-content;
  flex: 1;
  flex-direction: column;
  place-content: center;
  align-items: flex-start;
  gap: 15px;
}
.followBRLAOnSocial {
  color: #fff;
  font-size: 12.5px;
  line-height: 20px;
  font-weight: bold;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: 1.13px;
  text-transform: uppercase;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.divSocialGrid {
  width: min-content;
  height: min-content;
  align-items: flex-start;
  gap: 24px;
  padding: 0 96px 0 0;
}
.frame {
  width: 25px;
  height: 25px;
  overflow: visible;
}
.icon {
  
  height: 100%;
}
.frame4 {
  width: 21.88px;
  height: 25px;
  overflow: visible;
}
.icon2 {
  
  height: 100%;
}
.eMailInfoBrlaDigital {
  color: #fff;
  font-size: 18px;
  line-height: 18px;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.phone55113777742 {
  color: #fff;
  font-size: 18px;
  line-height: 18px;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.addressRuaUrussui125Cj84SaoPau {
  color: #fff;
  font-size: 18px;
  line-height: 18px;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.divRow2 {
  height: min-content;
  align-self: stretch;
  align-items: flex-start;
  gap: 85px;
  padding: 0 15px;
}
.frame31 {
  width: min-content;
  height: min-content;
  padding: 10px;
}
.logocoin {
  width: 215px;
  height: 215px;
  background-image: url('../../../public/assets/logocoin.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.footer {
  flex: 1;
  align-self: stretch;
  flex-direction: column;
  place-content: center;
  gap: 15px;
  padding: 4px 0;
}
.digitalAssetsAreSubjectToANumb {
  color: #a7a3b5;
  font-size: 11.5px;
  line-height: 18px;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.21px;
  height: min-content;
  align-self: stretch;
  text-align: justify;
  flex-direction: column;
}
._2023BRLADigital {
  color: #a7a3b5;
  font-size: 11.5px;
  line-height: 18px;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.21px;
  height: 12px;
  align-self: stretch;
  justify-content: center;
  flex-direction: column;
  
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdownContent {
  display: none;
  position: fixed;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  font-size: 16px; /* Ajuste o tamanho da fonte conforme necessário */
  
}

.dropdownContent a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown:hover .dropdownContent {
  display: block;
}

.dropbtn {
  background-color: transparent;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropbtn:hover, .dropbtn:focus {
  background-color: transparent;
}
