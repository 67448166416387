.root {
  width: 729px;
  height: min-content;
  flex-direction: column;
  padding: 0 20px;
  border-radius: 18px;
  background-image: linear-gradient(90deg, #0a1b23ff 0%, #08383fff 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  overflow: hidden;
  box-shadow: 0px 2px 4px -2px #0000001a, 0px 4px 6px -1px #0000001a;
}
.frame15 {
  height: 600px;
  align-self: stretch;
  flex-direction: column;
  place-content: center;
}
.frame6 {
  height: min-content;
  align-self: stretch;
  align-items: flex-start;
  gap: 104px;
}
.frame2 {
  height: min-content;
  flex: 1;
  flex-direction: column;
  place-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
}
.pIXQRCode {
  color: #fff;
  font-size: 18px;
  line-height: 27px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.image4 {
  width: 200px;
  height: 200px;
  background-image:/* webpackIgnore: true */ url('/assets/image4.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.frame16 {
  width: min-content;
  height: min-content;
  place-content: center;
  align-items: center;
  gap: 10px;
}
.pIXCODE123456789 {
  color: #fff;
  font-size: 11px;
  line-height: 27px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.image5 {
  width: 20px;
  height: 20px;
  background-image:/* webpackIgnore: true */ url('/assets/image5.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.checkOurComplianceAMLAndCFTPol {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.labelWrapper {
  font-size: 12px;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  flex-shrink: 1;
}
.label {
  color: #fff;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
}
.label2 {
  color: #fff;
  font-size: 12px;
  line-height: 18px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
}
.label3 {
  color: #fff;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
}
.frame4 {
  height: min-content;
  flex: 1;
  flex-direction: column;
  place-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
}
.onChainPayments {
  color: #fff;
  font-size: 18px;
  line-height: 27px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.image42 {
  width: 200px;
  height: 200px;
  background-image:/* webpackIgnore: true */ url('/assets/image42.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.frame19 {
  width: min-content;
  height: min-content;
  place-content: center;
  align-items: center;
  gap: 10px;
}
.walletAddressx123Wyz {
  color: #fff;
  font-size: 12px;
  line-height: 27px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.image52 {
  width: 20px;
  height: 20px;
  background-image:/* webpackIgnore: true */ url('/assets/image52.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.remindersThisWalletIsOnPolygon {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.textBlock {
  color: #fff;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
}
.list {
  font-size: 12px;
}
.list ::marker {
  color: #fff;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
}
.textBlock2 {
  margin-top: 0;
}
.labelWrapper2 {
  font-size: 12px;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  flex-shrink: 1;
}
.label4 {
  color: #fff;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
}
.label5 {
  color: #fff;
  font-size: 12px;
  line-height: 18px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
}
.label6 {
  color: #fff;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
}
.textBlock3 {
  margin-top: 0;
}
.labelWrapper3 {
  font-size: 12px;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  flex-shrink: 1;
}
.label7 {
  color: #fff;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
}
.label8 {
  color: #fff;
  font-size: 12px;
  line-height: 18px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
}
.textBlock4 {
  margin-top: 0;
}
.labelWrapper4 {
  font-size: 12px;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  flex-shrink: 1;
}
.label9 {
  color: #fff;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
}
.label10 {
  color: #fff;
  font-size: 12px;
  line-height: 18px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
}
.label11 {
  color: #fff;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
}



.main {
  top:80px !important;
  left:900px !important;
}