.root {
  opacity: 1;
  flex: 1;
  align-self: stretch;
  flex-direction: column;
  gap: 20px;
  padding: 27px 0;
  background-color: #f9f9fc;
}
.header {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  padding: 0 20px;
}
.divCbHeaderHead {
  height: min-content;
  align-self: stretch;
  place-content: space-between;
  align-items: center;
}
.heading1Home {
  color: #08383f;
  font-size: 32px;
  line-height: 54px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.75px;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.divActions {
  width: min-content;
  height: min-content;
  place-content: flex-end;
  align-items: flex-start;
  gap: 20px;
  padding: 9px 0;
}
.button {
  width: min-content;
  height: min-content;
  padding: 10.25px 17.44px 11.75px 18px;
  border-radius: 6.75px;
  background-color: #08383f;
}
.tRANSFER {
  color: #fff;
  font-size: 15.75px;
  line-height: 22.5px;
  font-weight: bold;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: 0.3938px;
  text-transform: uppercase;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
}
.button2 {
  width: min-content;
  height: min-content;
  padding: 10.25px 17.44px 11.75px 18px;
  border-radius: 6.75px;
  background-color: #08383f;
}
.dEPOSIT {
  color: #fff;
  font-size: 15.75px;
  line-height: 22.5px;
  font-weight: bold;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: 0.3938px;
  text-transform: uppercase;
  width: min-content;
  height: min-content;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
}
.frame27 {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  padding: 0 10px;
}
.section {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  padding: 20px 10px;
  border-radius: 18px;
  background-color: #fff;
  overflow: hidden;
  box-shadow: 0px 2px 4px -2px #0000001a, 0px 4px 6px -1px #0000001a;
}
.frame15 {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  place-content: center;
  gap: 2px;
}
.divContent {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.divFlex {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
}
.heading3RecentActivity {
  color: #08383f;
  font-size: 24px;
  line-height: 36px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.2px;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.frame6 {
  height: min-content;
  align-self: stretch;
  align-items: flex-start;
  gap: 104px;
}
.frame2 {
  height: min-content;
  flex: 1;
  flex-direction: column;
  place-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
}
.pIXQRCode {
  color: #08383f;
  font-size: 18px;
  line-height: 27px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.image4 {
  width: 200px;
  height: 200px;
  background-image:/* webpackIgnore: true */ url('/assets/image4.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.frame16 {
  width: min-content;
  height: min-content;
  place-content: center;
  align-items: center;
  gap: 10px;
}
.pIXCODE123456789 {
  color: #08383f;
  font-size: 11px;
  line-height: 27px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.image5 {
  width: 20px;
  height: 20px;
  background-image:/* webpackIgnore: true */ url('/assets/image5.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.checkOurComplianceAMLAndCFTPol {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.labelWrapper {
  font-size: 12px;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  flex-shrink: 1;
}
.label {
  color: #008884;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
}
.label2 {
  color: #008884;
  font-size: 12px;
  line-height: 18px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
}
.label3 {
  color: #008884;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
}
.frame4 {
  height: min-content;
  flex: 1;
  flex-direction: column;
  place-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
}
.onChainPayments {
  color: #08383f;
  font-size: 18px;
  line-height: 27px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.image42 {
  width: 200px;
  height: 200px;
  background-image:/* webpackIgnore: true */ url('/assets/image42.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.frame19 {
  width: min-content;
  height: min-content;
  place-content: center;
  align-items: center;
  gap: 10px;
}
.walletAddressx123Wyz {
  color: #08383f;
  font-size: 12px;
  line-height: 27px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.image52 {
  width: 20px;
  height: 20px;
  background-image:/* webpackIgnore: true */ url('/assets/image52.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.remindersThisWalletIsOnPolygon {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.textBlock {
  color: #008884;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
}
.list {
  font-size: 12px;
}
.list ::marker {
  color: #008884;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
}
.textBlock2 {
  margin-top: 0;
}
.labelWrapper2 {
  font-size: 12px;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  flex-shrink: 1;
}
.label4 {
  color: #008884;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
}
.label5 {
  color: #008884;
  font-size: 12px;
  line-height: 18px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
}
.label6 {
  color: #008884;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
}
.textBlock3 {
  margin-top: 0;
}
.labelWrapper3 {
  font-size: 12px;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  flex-shrink: 1;
}
.label7 {
  color: #008884;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
}
.label8 {
  color: #008884;
  font-size: 12px;
  line-height: 18px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
}
.textBlock4 {
  margin-top: 0;
}
.labelWrapper4 {
  font-size: 12px;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  flex-shrink: 1;
}
.label9 {
  color: #008884;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
}
.label10 {
  color: #008884;
  font-size: 12px;
  line-height: 18px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
}
.label11 {
  color: #008884;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
}
.frame3 {
  height: min-content;
  flex: 1;
  flex-direction: column;
  place-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
}
.customizeYourQRCode {
  color: #08383f;
  font-size: 18px;
  line-height: 27px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.frame17 {
  width: min-content;
  height: min-content;
  flex-direction: column;
  place-content: center;
  align-items: center;
  padding: 5px;
  outline: solid 1px #00dc84;
  outline-offset: -1px;
}
.backgroundColor {
  color: #08383f;
  font-size: 12px;
  line-height: 27px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  z-index: 0;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.image6 {
  z-index: 0;
  width: 200px;
  height: 63.2768px;
  background-image:/* webpackIgnore: true */ url('/assets/image6.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.frame18 {
  width: min-content;
  height: min-content;
  flex-direction: column;
  place-content: center;
  align-items: center;
  padding: 5px;
  outline: solid 1px #00dc84;
  outline-offset: -1px;
}
.qRCodeColor {
  color: #08383f;
  font-size: 12px;
  line-height: 27px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  z-index: 0;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.image62 {
  z-index: 0;
  width: 200px;
  height: 63.2768px;
  background-image:/* webpackIgnore: true */ url('/assets/image62.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.frame26 {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  padding: 0 10px;
}
.section2 {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  padding: 20px 10px;
  border-radius: 18px;
  background-color: #fff;
  overflow: hidden;
  box-shadow: 0px 2px 4px -2px #0000001a, 0px 4px 6px -1px #0000001a;
}
.frame152 {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  place-content: center;
  gap: 5px;
}
.divContent2 {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
}
.divFlex2 {
  height: min-content;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
}
.heading3RecentActivity2 {
  color: #08383f;
  font-size: 24px;
  line-height: 36px;
  font-weight: 500;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.2px;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.frame62 {
  height: min-content;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
}
.frame22 {
  height: min-content;
  flex: 1;
  flex-direction: column;
  place-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 0;
}
.pIXPayments {
  color: #08383f;
  font-size: 18px;
  line-height: 27px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.frame20 {
  height: min-content;
  align-self: stretch;
  align-items: center;
  gap: 10px;
}
.pIXCode {
  color: #08383f;
  font-size: 14px;
  line-height: 27px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  width: 96px;
  height: min-content;
  flex-direction: column;
}
.frame21 {
  height: min-content;
  flex: 1;
  place-content: center;
  padding: 2px;
  outline: solid 2px #00dc84;
  outline-offset: -2px;
  border-radius: 5px;
}
._1234567890123456 {
  color: #08383f;
  font-size: 12px;
  line-height: 27px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  z-index: 0;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.frame212 {
  height: min-content;
  align-self: stretch;
  align-items: center;
  gap: 10px;
}
.amount {
  color: #08383f;
  font-size: 14px;
  line-height: 27px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  width: 96px;
  height: min-content;
  flex-direction: column;
}
.frame213 {
  height: min-content;
  flex: 1;
  place-content: center;
  padding: 2px;
  outline: solid 2px #00dc84;
  outline-offset: -2px;
  border-radius: 5px;
}
.r250032 {
  color: #08383f;
  font-size: 12px;
  line-height: 27px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  z-index: 0;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.frame23 {
  height: min-content;
  align-self: stretch;
  place-content: center;
}
.frame214 {
  width: 207px;
  height: 31px;
  place-content: center;
  padding: 2px;
  border-radius: 5px;
}
.frame222 {
  width: min-content;
  height: min-content;
  padding: 2px 5px;
  border-radius: 5px;
  background-color: #08383f;
}
.sendPayment {
  color: #fff;
  font-size: 14px;
  line-height: 27px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}



.sendPayment:hover {
  background-color: #08383f;
}

.sendPayment2:hover {
  background-color: #08383f;
}

.sendPayment3:hover {
  background-color: #08383f;
}


.frame32 {
  height: min-content;
  flex: 1;
  flex-direction: column;
  place-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 0;
}
.onChainPayments2 {
  color: #08383f;
  font-size: 18px;
  line-height: 27px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.frame202 {
  height: min-content;
  align-self: stretch;
  align-items: center;
  gap: 10px;
}
.wallet {
  color: #08383f;
  font-size: 14px;
  line-height: 27px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  width: 96px;
  height: min-content;
  flex-direction: column;
}
.frame215 {
  height: min-content;
  flex: 1;
  place-content: center;
  padding: 2px;
  outline: solid 2px #00dc84;
  outline-offset: -2px;
  border-radius: 5px;
}
._12345678901234562 {
  color: #08383f;
  font-size: 12px;
  line-height: 27px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  z-index: 0;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.frame216 {
  height: min-content;
  align-self: stretch;
  align-items: center;
  gap: 10px;
}
.amount2 {
  color: #08383f;
  font-size: 14px;
  line-height: 27px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  width: 96px;
  height: min-content;
  flex-direction: column;
}
.frame217 {
  height: min-content;
  flex: 1;
  place-content: center;
  padding: 2px;
  outline: solid 2px #00dc84;
  outline-offset: -2px;
  border-radius: 5px;
}
.r2500322 {
  color: #08383f;
  font-size: 12px;
  line-height: 27px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  z-index: 0;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.frame232 {
  height: min-content;
  align-self: stretch;
  align-items: center;
  gap: 5px;
}
.sendToken {
  color: #08383f;
  font-size: 14px;
  line-height: 27px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  width: 101px;
  height: min-content;
  flex-direction: column;
}
.frame218 {
  height: min-content;
  flex: 1;
  place-content: center;
  padding: 2px;
  outline: solid 2px #00dc84;
  outline-offset: -2px;
  border-radius: 5px;
}
.uSDC {
  color: #08383f;
  font-size: 12px;
  line-height: 27px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  z-index: 0;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.frame223 {
  height: min-content;
  flex: 1;
  place-content: center;
  padding: 2px;
  outline: solid 2px #00dc84;
  outline-offset: -2px;
  border-radius: 5px;
}
._500USDCNet {
  color: #08383f;
  font-size: 12px;
  line-height: 27px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  z-index: 0;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.frame224 {
  width: min-content;
  height: min-content;
  padding: 2px 5px;
  border-radius: 5px;
  background-color: #08383f;
}
.sendPayment2 {
  color: #fff;
  font-size: 14px;
  line-height: 27px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.frame42 {
  height: min-content;
  flex: 1;
  flex-direction: column;
  place-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 0;
}
.bRLAAccountPayment {
  color: #08383f;
  font-size: 18px;
  line-height: 27px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.frame203 {
  height: min-content;
  align-self: stretch;
  align-items: center;
  gap: 10px;
}
.accountID {
  color: #08383f;
  font-size: 14px;
  line-height: 27px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  width: 96px;
  height: min-content;
  flex-direction: column;
}
.frame219 {
  height: min-content;
  flex: 1;
  place-content: center;
  padding: 2px;
  outline: solid 2px #00dc84;
  outline-offset: -2px;
  border-radius: 5px;
}
._12345678901234563 {
  color: #08383f;
  font-size: 12px;
  line-height: 27px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  z-index: 0;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.frame2110 {
  height: min-content;
  align-self: stretch;
  align-items: center;
  gap: 10px;
}
.amount3 {
  color: #08383f;
  font-size: 14px;
  line-height: 27px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  width: 96px;
  height: min-content;
  flex-direction: column;
}
.frame2111 {
  height: min-content;
  flex: 1;
  place-content: center;
  padding: 2px;
  outline: solid 2px #00dc84;
  outline-offset: -2px;
  border-radius: 5px;
}
.r2500323 {
  color: #08383f;
  font-size: 12px;
  line-height: 27px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  z-index: 0;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.frame233 {
  height: min-content;
  align-self: stretch;
  place-content: center;
}
.frame2112 {
  width: 207px;
  height: 31px;
  place-content: center;
  padding: 2px;
  border-radius: 5px;
}
.frame225 {
  width: min-content;
  height: min-content;
  padding: 2px 5px;
  border-radius: 5px;
  background-color: #08383f;
}
.sendPayment3 {
  color: #fff;
  font-size: 14px;
  line-height: 27px;
  font-weight: bold;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.footer {
  flex: 1;
  align-self: stretch;
  align-items: flex-end;
  padding: 0 20px;
}
._2023BRLADigitalAllRightsReserv {
  color: #08383f;
  font-size: 13.5px;
  line-height: 18px;
  font-weight: 500;
  font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  height: min-content;
  flex: 1;
  flex-direction: column;
}
.divMlAuto {
  height: min-content;
  flex: 1;
  place-content: flex-end;
  align-items: center;
  gap: 9px;
}
.linkTermsOfUse {
  color: #022c74;
  font-size: 12.5px;
  line-height: 18px;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.linkPrivacyPolicy {
  color: #022c74;
  font-size: 12.5px;
  line-height: 18px;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
.linkCookiePolicy {
  color: #022c74;
  font-size: 12.5px;
  line-height: 18px;
  font-family: Inter, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    'Liberation Sans', sans-serif;
  letter-spacing: -0.4px;
  width: min-content;
  height: min-content;
  white-space: nowrap;
  flex-direction: column;
}
