.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9990000;
  }
  
  .modal {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    width: 80%;
    max-width: 500px;
  }
  
  .closeButton {
    position: absolute;
    top: 10px;
    right: 15px;
    border: none;
    background: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  


  