.root {
    width: min-content;
    height: min-content;
    flex-direction: column;
    padding: 20px;
    border-radius: 16px;
    background-image: linear-gradient(90deg, #0a1b23ff 0%, #08383fff 100%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    overflow-y: auto;
  
  }
  .menu_frame {
    height: 184px;
    align-self: stretch;
    flex-direction: column;
    place-content: space-between;
    align-items: flex-start;
    padding: 5px 20px;
    border-radius: 16px;
  }
  .frame1 {
    width: min-content;
    height: min-content;
    place-content: center;
    align-items: center;
    gap: 8px;
  }
  .solarCardSendBroken {
    width: 30px;
    height: 30px;
  }
  .icon {
    width: 100%;
    height: 100%;
  }
  .transferir {
    color: #fff;
    font-size: 14px;
    line-height: 14px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: 1.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .frame2 {
    width: min-content;
    height: min-content;
    place-content: center;
    align-items: center;
    gap: 8px;
  }
  .fontistoMoneySymbol {
    width: 30px;
    height: 30px;
  }
  .icon2 {
    width: 100%;
    height: 100%;
  }
  .converter {
    color: #fff;
    font-size: 14px;
    line-height: 14px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: 1.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  .frame3 {
    width: min-content;
    height: min-content;
    place-content: center;
    align-items: center;
    gap: 8px;
  }
  .streamlineMoneyWalletMoneyPaym {
    width: 30px;
    height: 30px;
  }
  .icon3 {
    width: 100%;
    height: 100%;
  }
  .depositar {
    color: #fff;
    font-size: 14px;
    line-height: 14px;
    font-weight: bold;
    font-family: Gordita, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
      'Liberation Sans', sans-serif;
    letter-spacing: 1.4px;
    width: min-content;
    height: min-content;
    white-space: nowrap;
    flex-direction: column;
  }
  